import styled from 'styled-components';

export const Separator = styled.hr`
  background: #eaeaea;
  color: #eaeaea;
  border-color: transparent;
  border: none;
  height: 1px;
  margin: 0;
`;
