export const parseEmailAddress = (email) => {
  const splitEmail = email.split('@');
  let maskedName = '';
  const name = splitEmail[0];
  const domain = splitEmail[1];
  // just in case length of email name is leser than 4
  if (name.length <= 4) {
    maskedName = '*'.repeat(name.length);
  } else {
    const maskedLength = name.length - 4;
    maskedName =
      name.slice(0, 2) + '*'.repeat(maskedLength) + name.slice(name.length - 2, name.length);
  }
  return `${maskedName}@${domain}`;
};

export const parsePhoneNum = (phoneNum, countryCode) => {
  const maskedLength = phoneNum.length - 4;
  return `+${countryCode}${'*'.repeat(maskedLength)}${phoneNum.slice(
    phoneNum.length - 4,
    phoneNum.length,
  )}`;
};

export const formatPhoneNumber = (displayPhone) => {
  const start = displayPhone.slice(0, 4);
  const end = displayPhone.slice(4, 8);
  return `+65 ${start} ${end}`;
};

export const displayPhoneParser = (displayPhone) => {
  if (displayPhone.includes('+65') || !/\d/.test(displayPhone)) {
    return displayPhone;
  } else {
    return formatPhoneNumber(displayPhone);
  }
};
