/* eslint-disable react/prop-types */
import React from 'react';
import SvgLoading from '@ntuctech/devex-tangram/Icon/SvgLoading';
import styled, { withTheme } from 'styled-components';

const LoaderSC = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

function Loader({ theme }) {
  const { ColorBackgroundGreyLight1 } = theme;
  return (
    <LoaderSC data-testid="pageLoader">
      <SvgLoading size="l" color={ColorBackgroundGreyLight1} />
    </LoaderSC>
  );
}

export default withTheme(Loader);
