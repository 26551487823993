import React from 'react';
import Box from '@ntuctech/devex-tangram/Box';
import styled from 'styled-components';

const ContentBlockSC = styled(Box)`
  width: 100%;
  max-width: 45.75rem;

  &.page__ContentBlock--compact {
    max-width: 30rem;
  }

  border: 0.0625rem solid #eaeaea;
  background-color: #ffffff;
  border-radius: 0.25rem;

  margin: 1.25rem auto;
  padding: 2.5rem 3.5rem;

  @media (max-width: 767px) {
    padding: 1.5rem 1.75rem;
    margin: 1rem auto;
  }
`;

const ContentBlock = ({ compact, children, ...rest }) => (
  <ContentBlockSC className={compact ? 'page__ContentBlock--compact' : ''} {...rest}>
    {children}
  </ContentBlockSC>
);

export default ContentBlock;
