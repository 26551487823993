const NAV_AS_PROFILE = ['/verify-mobile', '/verify-email', '/mi-callback'];
const NAV_AS_LINKPOINTS = [
  '/linkpoints/transaction-history',
  '/linkpoints/cards',
  '/linkpoints/restore',
];
const NAV_AS_SETTINGS = ['/settings/mfa', '/settings/account-termination'];

const isNavActive = (currentPath, targetUrl) =>
  currentPath === targetUrl ||
  (NAV_AS_PROFILE.includes(currentPath) && targetUrl === '/') ||
  (NAV_AS_LINKPOINTS.includes(currentPath) && targetUrl === '/linkpoints') ||
  (NAV_AS_SETTINGS.includes(currentPath) && targetUrl === '/settings');

export default isNavActive;
