import React from 'react';
import ButtonText from '@ntuctech/devex-tangram/Button/ButtonText';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import isNavActive from '../../services/navigation';

const ButtonTextSC = styled(ButtonText)`
  width: 100%;
  padding: 0 1rem 0 2rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  justify-content: left;

  &:focus::before,
  &:active::before {
    box-shadow: none !important;
  }

  &.leftNav__link--active {
    background: ${(props) => props.theme.ColorBackgroundButtonTextHover};
  }
  /** TODO - remove temp fix for purple circle once resolved on tangram */
  & > svg > * {
    fill: #666666;
  }
  &.leftNav__link--active > svg > * {
    fill: #73308a;
  }
`;

function NavLink(props) {
  const router = useRouter();

  const { href, children, ...rest } = props;

  const extraProps = {};

  const navActive = React.useMemo(() => isNavActive(router.pathname, href), [router, href]);

  if (!navActive) {
    extraProps.color = 'grey';
  }

  const classNames = clsx({
    'leftNav__link--active': navActive,
  });

  return (
    <Link href={href} passHref>
      <ButtonTextSC className={classNames} {...extraProps} {...rest} compact>
        {children}
      </ButtonTextSC>
    </Link>
  );
}

export default NavLink;
