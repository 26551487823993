import React from 'react';
import Box from '@ntuctech/devex-tangram/Box';
import styled from 'styled-components';

const StyledPageContent = styled(Box)`
  div.accProfile-row:first-child {
    margin-top: 1.5rem;
  }
  div.accProfile-row:last-child {
    margin-bottom: 0rem;
  }
`;

const PageContent = ({ children }) => <StyledPageContent>{children}</StyledPageContent>;

export default PageContent;
