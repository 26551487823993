const getUrlFlowParam = (urlParam) => {
  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search);
    return params.get(urlParam);
  } else {
    return null;
  }
};

export const isNudgeFlow = () => {
  return getUrlFlowParam('link_account_matching_confidence');
};

export const isHpbFlow = () => {
  if (typeof window !== 'undefined') {
    const url = window.location.href;
    return url.includes('integration/hpb');
  } else {
    return null;
  }
};
