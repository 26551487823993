import React from 'react';
import Link from '@ntuctech/devex-tangram/Link';
import styled from 'styled-components';

import { FLAGS } from '../../services/feature-flag';
import { useFeatures } from '../DynamicFeatureProvider';

const PrivacyNoticeLinkSC = styled(Link)`
  display: inline-block;
  font-weight: normal;
  color: #999999 !important;

  &:focus,
  &:hover,
  &:active {
    color: #999999;
    box-shadow: 0 0.0625rem #999999;
  }
`;

const PrivacyNoticeLink = () => {
  const { isFlagEnabled, getFlagConfig } = useFeatures();
  const isLinkpassTermsofUseFlagEnabled = isFlagEnabled(FLAGS.LINKPASS_TERMS_OF_USE);
  const getLinkpassTermsofUseLink = JSON.parse(
    getFlagConfig(FLAGS.LINKPASS_TERMS_OF_USE),
  )?.linkpass_terms_of_use;

  const privacyNoticeLabel = isLinkpassTermsofUseFlagEnabled ? 'Terms of Use' : 'Privacy Notice';
  const privacyNoticeUrl = isLinkpassTermsofUseFlagEnabled
    ? getLinkpassTermsofUseLink
    : 'https://help.fairprice.com.sg/hc/en-us/articles/4464676591129-Privacy-Notice';

  return (
    <PrivacyNoticeLinkSC
      href={privacyNoticeUrl}
      target="_blank"
      title={privacyNoticeLabel}
      aria-label={privacyNoticeLabel}
      size="s"
    >
      {privacyNoticeLabel}
    </PrivacyNoticeLinkSC>
  );
};

export default PrivacyNoticeLink;
