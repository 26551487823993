import amplitude from 'amplitude-js';

import { ctaLocations, ctaSublocations, eventNames, GTM_ENV_VAR } from './events';

export const initAmplitude = (userId) => {
  amplitude.getInstance().init(process.env.AMPLITUDE_TOKEN, userId);
};

const gaOperations = (eventName, { dataLayer }) => {
  dataLayer?.push({
    event: 'Pageview',
    [GTM_ENV_VAR.AMPLITUDE_EVENT_NAME]: eventName,
    [GTM_ENV_VAR.EVENT_ACTION]: eventName,
    ...(eventName === 'hpb login success' && { scid_auth_ref: 'cmpg_external_integration_hpb' }),
  });
};

const getCtaLocation = (backTo, vertical, ctaLocation) => {
  if (vertical === 'account') {
    return backTo ? ctaLocations.ENTRANCE : ctaLocations.ACCOUNT;
  } else {
    return ctaLocation;
  }
};

const ampOperations = (
  eventName,
  { backTo, user, vertical, ctaLocation, ctaSubLocation, category, message, option },
) => {
  if (user?.association?.fairprice) {
    const userProperty = {
      'link plus id': user.association.linkplus,
      'user id': user.association.fairprice,
      'linkpass id': user.uid,
    };

    const eventProperties = {
      vertical: vertical,
      'call to action location': getCtaLocation(backTo, vertical, ctaLocation),
      'call to action sublocation': ctaSubLocation
        ? ctaSubLocation
        : /* eslint-disable */
        backTo
        ? ctaSublocations.ENTRANCE
        : 'null',
      category,
      message,
      'option selected': option,
      ...(eventName === 'hpb login success' && { scid_auth_ref: 'cmpg_external_integration_hpb' }),
    };
    amplitude.getInstance().setUserProperties(userProperty);
    amplitude.getInstance().logEvent(eventName, eventProperties);
  }
};

const gaHandlers = {
  [eventNames.SAM_LINKPOINTS_PAGE_VIEWED]: gaOperations,
  [eventNames.SAM_NOTIFICATIONS_PAGE_VIEWED]: gaOperations,
  [eventNames.SAM_PROFILE_PAGE_VIEWED]: gaOperations,
  [eventNames.SAM_SETTINGS_PAGE_VIEWED]: gaOperations,
  [eventNames.HPB_LOGIN_SUCCESS]: gaOperations,
};

const ampHandlers = {
  [eventNames.SAM_LINKPOINTS_PAGE_VIEWED]: ampOperations,
  [eventNames.SAM_NOTIFICATIONS_PAGE_VIEWED]: ampOperations,
  [eventNames.SAM_PROFILE_PAGE_VIEWED]: ampOperations,
  [eventNames.SAM_SETTINGS_PAGE_VIEWED]: ampOperations,
  [eventNames.ACC_REC_MERGE_ACC_VIEWED]: ampOperations,
  [eventNames.ACC_REC_MERGE_ACC_CLICKED]: ampOperations,
  [eventNames.ACC_REC_REVIEW_ACC_VIEWED]: ampOperations,
  [eventNames.ACC_REC_REVIEW_ACC_CLICKED]: ampOperations,
  [eventNames.ACC_REC_REVIEW_ACC_CLOSED]: ampOperations,
  [eventNames.ACC_REC_REVIEW_ACC_CONFIRMATINO_CLICKED]: ampOperations,
  [eventNames.ACC_REC_REVIEW_ACC_CONFIRMATINO_CLICKED]: ampOperations,
  [eventNames.ACC_RECC_MERGE_ACC_VER_CLICKED]: ampOperations,
  [eventNames.ACC_REC_MERGE_COMPLETE]: ampOperations,
  [eventNames.ACC_REC_CLOSE_BUTTON]: ampOperations,
  [eventNames.HPB_LOGIN_SUCCESS]: ampOperations,
};

const handlers = [ampHandlers, gaHandlers];

export const track = (eventName, props) => {
  handlers.forEach((handler) => handler[eventName] && handler[eventName](eventName, props));
};
