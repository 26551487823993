import React from 'react';
import clsx from 'clsx';
import styled from 'styled-components';

const FormWrapperSC = styled.div`
  width: calc(100% + 3rem);

  margin-left: -1.5rem;
  padding: 0 1.5rem;

  border: 0.0625rem solid #eaeaea;
  border-radius: 0.5rem;

  &.noBorder {
    border: none;
  }

  & .accProfile-row {
    margin: 1.5rem 0;
    align-items: flex-start;
  }

  div.accProfile-row:last-child {
    margin-bottom: 1.5rem;
  }

  @media (max-width: 767px) {
    width: calc(100% + 2rem);
    margin-left: -1rem;
    padding: 0 1rem;

    & .accProfile-row {
      margin: 1rem 0;
    }

    div.accProfile-row:last-child {
      margin-bottom: 1rem;
    }

    & .accProfile-title,
    & .accProfile-action {
      display: none;
    }
  }

  @media (min-width: 768px) {
    &.hideInputLabel ._ndsFormControl__label {
      display: none;
    }
    & ._ndsFormControl {
      margin-bottom: 1.5rem;
    }
  }
`;

const FormWrapper = (props) => {
  const { children, hideInputLabel, noBorder } = props;
  return (
    <FormWrapperSC className={clsx({ hideInputLabel: hideInputLabel, noBorder: noBorder })}>
      {children}
    </FormWrapperSC>
  );
};

export default FormWrapper;
