import React from 'react';
import styled from 'styled-components';

const ProfileRowSC = styled.div`
  width: 100%;
  margin: 2rem 0;

  display: flex;
  align-items: center;

  &.alignTop {
    align-items: start;
  }

  .profileButton {
    font-weight: 700;
  }

  @media (max-width: 767px) {
    margin: 1.5rem 0;
    align-items: baseline;
    flex-wrap: wrap;
  }
`;

const ProfileRow = (props) => {
  const { children, className = '' } = props;

  return <ProfileRowSC className={`accProfile-row ${className}`}>{children}</ProfileRowSC>;
};

export default ProfileRow;
