import React from 'react';
import SvgArrowLeft from '@ntuctech/devex-tangram/Icon/SvgArrowLeft';
import styled from 'styled-components';

const BackArrow = styled(SvgArrowLeft)`
  margin-top: 0.2rem;
  cursor: pointer;
`;

const BackButton = (props) => {
  return <BackArrow color="#333333" {...props} />;
};

export default BackButton;
