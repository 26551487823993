import React from 'react';
import ButtonContained from '@ntuctech/devex-tangram/Button/ButtonContained';
import ButtonText from '@ntuctech/devex-tangram/Button/ButtonText';
import Modal from '@ntuctech/devex-tangram/Modal';
import ModalActions from '@ntuctech/devex-tangram/ModalActions';
import ModalContent from '@ntuctech/devex-tangram/ModalContent';
import ModalTitle from '@ntuctech/devex-tangram/ModalTitle';
import Typography from '@ntuctech/devex-tangram/Typography';
import styled from 'styled-components';

const ModalActionsSC = styled(ModalActions)``;

const SimpleModal = ({
  open,
  title,
  body,
  ctaText,
  ctaAction,
  secondaryText,
  secondaryAction,
  testId,
  loading,
  destructive,
}) => {
  return (
    <Modal data-testid={`${testId || 'SimpleModal'}`} open={open}>
      <ModalTitle>{title}</ModalTitle>
      <ModalContent>
        <Typography>{body}</Typography>
      </ModalContent>
      <ModalActionsSC>
        {secondaryText ? (
          <ButtonText
            onClick={secondaryAction}
            data-testid={`${testId || 'SimpleModal'}SecondaryAction`}
          >
            {secondaryText}
          </ButtonText>
        ) : null}
        <ButtonContained
          onClick={ctaAction}
          data-testid={`${testId || 'SimpleModal'}Action`}
          loading={loading}
          color={destructive && 'destructive'}
        >
          {ctaText}
        </ButtonContained>
      </ModalActionsSC>
    </Modal>
  );
};

export default SimpleModal;
