import React from 'react';
import SvgInfo from '@ntuctech/devex-tangram/Icon/SvgInfo';
import Typography from '@ntuctech/devex-tangram/Typography';
import styled from 'styled-components';

import { FLAGS } from '../../services/feature-flag';
import { formatPhoneNumber } from '../../services/utils';
import { useFeatures } from '../DynamicFeatureProvider';
import EditAddressForm from '../EditAddressForm';
import EditNameForm from '../EditNameForm';
import HocProfileManager from '../HocProfileManager';
import ProfileAction from '../ProfileAction';
import ProfileContent, { ProfileContentRow } from '../ProfileContent';
import ProfileRow from '../ProfileRow';
import ProfileTitle from '../ProfileTitle';
import { CustomTooltip, InactiveLabel, VerifiedBadge } from '../Theme';

const PROFILE_DATA_LABEL = {
  name: 'Preferred name',
  dateOfBirth: 'Date of birth',
  settings: 'Settings',
};

const TypographyMobileSc = styled(Typography)`
  margin-left: 0.25rem;
  margin-right: auto;
  color: #666666;

  @media (max-width: 767px) {
    margin: 0;
  }
`;

const TypographyEmailSc = styled(Typography)`
  max-width: 19rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 767px) {
    max-width: 10rem;
  }
`;

const MobileContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 845px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const RenderPhoneContent = ({ phoneVerifiedProp, isNewProfilePageEnabledProp }) => {
  return phoneVerifiedProp ? (
    <VerifiedBadge data-testid="badgePhoneVerified" />
  ) : (
    isNewProfilePageEnabledProp && (
      <CustomTooltip titleText={'Enables secure mobile login for verification via mobile.'}>
        <SvgInfo data-testid="phoneInfoIcon" />
      </CustomTooltip>
    )
  );
};

const renderEditButtonText = (isMethodVerified, isNewProfilePageEnabledProp) => {
  if (isNewProfilePageEnabledProp) {
    return isMethodVerified ? 'Edit' : 'Edit and verify';
  }
  return 'Edit';
};

const Profile = (props) => {
  const {
    // profile data
    profile,
    setLocalUser,
    // editing state & unsaved changes checking
    pageContext,
    setIsEditing,
    handleFinishEditing,
    // actions
    handleOpenForm,
    // alerts
    showSuccessToast,
    openErrorModal,
  } = props;

  const {
    name,
    email,
    emailVerified,
    displayPhone,
    isPhoneEmpty,
    phoneVerified,
    residentialAddress,
  } = profile;
  const { isFlagEnabled } = useFeatures();
  const isNewProfilePageEnabled = isFlagEnabled(FLAGS.PROFILE_VERIFICATION_REVAMP);
  const getPhone = displayPhone.split('+65')[1];
  const parsedPhone = formatPhoneNumber(getPhone);

  // post-update callback actions
  const profileUpdateCallback = React.useCallback(
    (error, res, from = null) => {
      if (error) {
        openErrorModal(error);
      } else {
        showSuccessToast(`${PROFILE_DATA_LABEL[from] || 'Profile'} updated successfully`);
        setLocalUser((oldUser) => ({ ...oldUser, ...res }));
        handleFinishEditing();
      }
    },
    [openErrorModal, setLocalUser, showSuccessToast, handleFinishEditing],
  );

  return (
    <>
      {/* Name */}
      {pageContext === 'name' ? (
        <EditNameForm
          initialValues={{ name }}
          handleCallback={profileUpdateCallback}
          onClose={handleFinishEditing}
          setIsEditing={setIsEditing}
        />
      ) : (
        <ProfileRow>
          <ProfileTitle>{PROFILE_DATA_LABEL.name}</ProfileTitle>
          <ProfileContent className="accTextOverflow">
            <Typography
              variant="body2"
              data-testid="DisplayName"
              data-dd-action-name="Profile name"
            >
              {name}
            </Typography>
          </ProfileContent>
          <ProfileAction
            onClick={handleOpenForm('name')}
            data-testid="EditNameLink"
            className="profileButton"
          >
            Edit
          </ProfileAction>
        </ProfileRow>
      )}
      {/* Mobile number */}
      <ProfileRow>
        <ProfileTitle>Mobile number</ProfileTitle>
        <ProfileContentRow>
          <MobileContentWrapper>
            <ProfileContentRow>
              <Typography
                variant="body2"
                data-testid="DisplayPhone"
                data-dd-action-name="Profile phone number"
              >
                {isPhoneEmpty ? <InactiveLabel>Not added</InactiveLabel> : parsedPhone}
              </Typography>
              <RenderPhoneContent
                isNewProfilePageEnabledProp={isNewProfilePageEnabled}
                phoneVerifiedProp={phoneVerified}
              />
            </ProfileContentRow>
            {!phoneVerified && isNewProfilePageEnabled && (
              <TypographyMobileSc variant="body3">Recommend to verify</TypographyMobileSc>
            )}
          </MobileContentWrapper>
        </ProfileContentRow>
        <ProfileAction
          onClick={handleOpenForm('verify-mobile')}
          data-testid="EditPhoneLink"
          className="profileButton"
        >
          {renderEditButtonText(phoneVerified, isNewProfilePageEnabled)}
        </ProfileAction>
      </ProfileRow>
      {/* Email */}
      <ProfileRow>
        <ProfileTitle>Email</ProfileTitle>
        <ProfileContentRow>
          <TypographyEmailSc
            variant="body2"
            data-testid="DisplayEmail"
            data-dd-action-name="Profile email"
          >
            {email}
          </TypographyEmailSc>
          {emailVerified ? (
            <VerifiedBadge data-testid="badgeEmailVerified" />
          ) : (
            isNewProfilePageEnabled && (
              <CustomTooltip
                titleText={'Receive a verification code via email to confirm your identity.'}
              >
                <SvgInfo />
              </CustomTooltip>
            )
          )}
        </ProfileContentRow>
        <ProfileAction
          onClick={handleOpenForm('verify-email')}
          data-testid="EditEmailLink"
          className="profileButton"
        >
          {renderEditButtonText(emailVerified, isNewProfilePageEnabled)}
        </ProfileAction>
      </ProfileRow>
      {/* Residential address */}
      {pageContext === 'address' ? (
        <EditAddressForm
          initialFormData={residentialAddress}
          handleCallback={profileUpdateCallback}
          closeForm={handleFinishEditing}
          mode={residentialAddress ? 'edit' : 'add'}
        />
      ) : (
        <ProfileRow className="alignTop">
          <ProfileTitle>Residing address</ProfileTitle>
          <ProfileContentRow className="accTextWrap">
            <Typography
              variant="body2"
              data-testid="DisplayAddress"
              data-dd-action-name="Profile address"
            >
              {residentialAddress ? (
                residentialAddress.full_address
              ) : (
                <InactiveLabel>Not added</InactiveLabel>
              )}
            </Typography>
          </ProfileContentRow>
          <ProfileAction
            onClick={handleOpenForm('address')}
            data-testid="EditAddressLink"
            className="profileButton"
          >
            {residentialAddress ? 'Edit' : 'Add'}
          </ProfileAction>
        </ProfileRow>
      )}
    </>
  );
};

export default HocProfileManager(Profile);
