import { FLAGS_VALUES } from '../../services/feature-flag';

export const setupSplitConfig = (uid) => ({
  startup: {
    requestTimeoutBeforeReady: 3,
    retriesOnFailureBeforeReady: 0,
  },
  core: {
    authorizationKey: process.env.SPLIT_IO_KEY,
    key: uid,
    trafficType: 'user',
  },
  sync: {
    splitFilters: [
      {
        type: 'byName',
        values: FLAGS_VALUES,
      },
    ],
  },
  storage: {
    type: 'LOCALSTORAGE',
  },
  debug: process.env.NODE_ENV === 'development',
});
