import React from 'react';
import Box from '@ntuctech/devex-tangram/Box';
import ButtonContained from '@ntuctech/devex-tangram/Button/ButtonContained';
import ButtonOutlined from '@ntuctech/devex-tangram/Button/ButtonOutlined';
import clsx from 'clsx';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  border-top: 0.0625rem solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.profile__task--primary {
    border-top: none;
    padding-bottom: 0;
  }

  & > button {
    margin: 0;
  }

  & .task__desc--with-icon {
    display: flex;
    align-items: flex-end;
  }

  & .task__desc--with-icon > svg {
    margin-left: 0.25rem;
  }

  & .ndsHidden-xs-down.task__desc--with-icon > svg {
    height: 0.875rem;
    position: relative;
    top: -0.125rem;
  }

  & .ndsHidden-s-up.task__desc--with-icon > svg {
    height: 0.8125rem;
  }
`;

const TaskRow = (props) => {
  const { children, taskName, ctaText, ctaAction, isTopPriority, ...rest } = props;

  const CtaComp = React.useMemo(
    () => (isTopPriority ? ButtonContained : ButtonOutlined),
    [isTopPriority],
  );

  const testId = `task__${taskName}`;

  return (
    <StyledBox
      {...rest}
      py={3}
      className={clsx({ 'profile__task--primary': isTopPriority })}
      data-testid={testId}
    >
      {children}

      <CtaComp onClick={ctaAction} data-testid={`${testId}--cta`}>
        {ctaText}
      </CtaComp>
    </StyledBox>
  );
};

export default TaskRow;
