import React from 'react';
import Box from '@ntuctech/devex-tangram/Box';
import Typography from '@ntuctech/devex-tangram/Typography';

import MainGirlConfused from './../../components/Theme/MainGirlConfused';

export default function ErrorContent({
  title = 'Oh no, something went wrong',
  message = 'Please refresh and try again.',
}) {
  return (
    <div
      css={`
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      `}
    >
      <div>
        {
          // eslint-disable-next-line @next/next/no-img-element
          <img
            css={`
              width: 7.5rem;
              height: 2rem;
              position: absolute;
              top: 2rem;
              left: 2rem;
            `}
            src="https://media.nedigital.sg/linkpass/files/d7804ea8-2fb6-4cbe-bd6d-d628e2264fc0/Linkpass_logo.svg"
            alt="LinkPass logo"
            title="LinkPass logo"
          />
        }
        <MainGirlConfused
          css={`
            max-width: 25rem;
            width: 75vw;
            will-change: width;
            transition: width 0.3s linear;
          `}
        />
        <Box mb="1.5" mt="4">
          <Typography variant="h3">{title}</Typography>
        </Box>
        <Typography variant="body3">{message}</Typography>
      </div>
    </div>
  );
}
