/* eslint-disable max-len */
import React from 'react';

const MainGirlConfused = () => (
  <svg
    viewBox="0 0 444 322"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="ErrorIllustration"
    data-testid="ErrorIllustration"
  >
    <path
      d="M86 58C86 49.1634 93.1634 42 102 42H375C383.837 42 391 49.1634 391 58V282C391 290.837 383.837 298 375 298H102C93.1634 298 86 290.837 86 282V58Z"
      fill="#E8D2EF"
      fillOpacity="0.6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M390.999 74.5H85.7305V73H390.999V74.5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M110.25 55.5C108.179 55.5 106.5 57.1789 106.5 59.25C106.5 61.3211 108.179 63 110.25 63C112.321 63 114 61.3211 114 59.25C114 57.1789 112.321 55.5 110.25 55.5ZM105 59.25C105 56.3505 107.351 54 110.25 54C113.149 54 115.5 56.3505 115.5 59.25C115.5 62.1495 113.149 64.5 110.25 64.5C107.351 64.5 105 62.1495 105 59.25Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.75 55.5C126.679 55.5 125 57.1789 125 59.25C125 61.3211 126.679 63 128.75 63C130.821 63 132.5 61.3211 132.5 59.25C132.5 57.1789 130.821 55.5 128.75 55.5ZM123.5 59.25C123.5 56.3505 125.851 54 128.75 54C131.649 54 134 56.3505 134 59.25C134 62.1495 131.649 64.5 128.75 64.5C125.851 64.5 123.5 62.1495 123.5 59.25Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.25 55.5C145.179 55.5 143.5 57.1789 143.5 59.25C143.5 61.3211 145.179 63 147.25 63C149.321 63 151 61.3211 151 59.25C151 57.1789 149.321 55.5 147.25 55.5ZM142 59.25C142 56.3505 144.351 54 147.25 54C150.149 54 152.5 56.3505 152.5 59.25C152.5 62.1495 150.149 64.5 147.25 64.5C144.351 64.5 142 62.1495 142 59.25Z"
      fill="white"
    />
    <rect y="199" width="152" height="123" rx="16" fill="#C186D5" />
    <path d="M0 222H152" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
    <circle cx="15.5" cy="211.5" r="3.5" stroke="white" strokeWidth="1.5" />
    <circle cx="28.5" cy="211.5" r="3.5" stroke="white" strokeWidth="1.5" />
    <circle cx="41.5" cy="211.5" r="3.5" stroke="white" strokeWidth="1.5" />
    <rect x="319" width="125" height="82" rx="16" fill="#FEB0AC" />
    <path d="M319 23H444" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
    <circle cx="334.5" cy="12.5" r="3.5" stroke="white" strokeWidth="1.5" />
    <circle cx="347.5" cy="12.5" r="3.5" stroke="white" strokeWidth="1.5" />
    <circle cx="360.5" cy="12.5" r="3.5" stroke="white" strokeWidth="1.5" />
    <g clipPath="url(#clip0)">
      <path
        d="M247.064 75.3092C247.064 75.3092 251.104 70.7092 255.327 75.1252C259.551 79.5412 252.022 91.1332 246.146 86.9012C240.454 82.6692 247.064 75.3092 247.064 75.3092Z"
        fill="#FFAF87"
      />
      <path
        d="M205.381 79.1732C205.381 79.1732 200.79 75.1252 197.117 80.0932C193.445 85.0612 202.443 95.5492 207.584 90.5812C212.726 85.6132 205.381 79.1732 205.381 79.1732Z"
        fill="#EB855A"
      />
      <path
        d="M202.649 92.6052C203.561 92.6052 204.301 91.8637 204.301 90.9492C204.301 90.0346 203.561 89.2932 202.649 89.2932C201.736 89.2932 200.996 90.0346 200.996 90.9492C200.996 91.8637 201.736 92.6052 202.649 92.6052Z"
        fill="#73308A"
      />
      <path
        d="M248.35 89.6611C249.263 89.6611 250.003 88.9197 250.003 88.0051C250.003 87.0905 249.263 86.3491 248.35 86.3491C247.437 86.3491 246.697 87.0905 246.697 88.0051C246.697 88.9197 247.437 89.6611 248.35 89.6611Z"
        fill="#73308A"
      />
      <path
        d="M225.853 93.1077C242.256 92.1911 254.77 77.3837 253.805 60.0345C252.839 42.6852 238.759 29.3639 222.356 30.2805C205.953 31.1971 193.439 46.0045 194.405 63.3538C195.37 80.703 209.45 94.0243 225.853 93.1077Z"
        fill="#231815"
      />
      <path
        d="M233.476 41.4533C242.705 41.4533 250.186 33.9567 250.186 24.7093C250.186 15.4619 242.705 7.96533 233.476 7.96533C224.247 7.96533 216.766 15.4619 216.766 24.7093C216.766 33.9567 224.247 41.4533 233.476 41.4533Z"
        fill="#231815"
      />
      <path
        d="M261.938 222.141L261.387 214.413L212.909 216.805V224.349C212.909 224.349 202.075 234.837 197.668 304.021H284.891C284.891 303.837 294.44 242.565 261.938 222.141Z"
        fill="black"
      />
      <path
        d="M120.177 135.845C120.544 134.925 121.646 134.557 122.564 134.741L129.91 136.213L122.564 132.533C121.646 131.981 121.279 130.877 121.646 129.773C122.197 128.669 123.483 128.301 124.584 128.853L131.379 132.533L124.952 127.381C124.217 126.645 124.033 125.357 124.584 124.621C125.319 123.701 126.788 123.517 127.522 124.253L135.786 130.325C136.336 130.693 136.887 130.509 137.622 130.325L142.947 128.853L139.458 127.933C135.051 127.197 133.949 127.013 133.949 125.725C133.949 124.621 134.684 123.701 135.786 123.517L142.396 123.333C145.702 123.333 148.089 123.701 150.292 125.541H150.476C154.516 128.301 153.781 134.005 153.781 135.293C153.781 135.477 153.781 135.661 153.965 135.845L185.916 188.285C186.283 189.021 186.1 189.941 185.365 190.493L170.491 200.245C169.573 200.797 168.839 199.693 168.471 198.773L144.232 140.997C144.049 140.261 143.131 139.893 142.396 139.893L137.806 140.629C137.255 140.813 136.887 140.813 136.336 140.813C135.418 140.813 134.684 140.813 133.766 140.629L121.646 138.605C120.361 138.421 119.626 136.949 120.177 135.845Z"
        fill="#FFAF87"
      />
      <path
        d="M198.587 124.805C198.587 124.805 190.14 120.389 171.594 198.037L189.222 205.397L211.257 140.445L198.587 124.805Z"
        fill="#FFAF87"
      />
      <path
        d="M168.472 198.773L149.742 152.037L159.474 145.045L187.019 192.333C190.875 200.061 192.527 205.397 185.182 209.261C177.654 211.653 171.043 206.133 168.472 198.773Z"
        fill="#FFAF87"
      />
      <path
        d="M340.899 103.829C340.165 103.093 339.063 103.093 338.329 103.461L331.902 107.325L337.594 101.437C338.329 100.701 338.329 99.4132 337.594 98.6772C336.676 97.7572 335.391 97.9412 334.656 98.6772L329.331 104.381L333.738 97.3892C334.289 96.4692 333.922 95.1812 333.187 94.6292C332.085 93.8932 330.8 94.2613 330.249 95.3652L324.556 103.829C324.189 104.381 323.638 104.381 322.904 104.565L317.395 104.933L320.333 102.909C324.373 100.885 325.291 100.149 324.924 99.0452C324.556 97.9412 323.455 97.3892 322.537 97.5732L316.293 99.4132C313.172 100.517 310.968 101.621 309.499 104.013C309.499 104.013 309.499 104.013 309.315 104.197C306.377 108.245 308.948 113.397 309.315 114.501C309.315 114.685 309.315 114.869 309.315 115.053L296.278 175.037C296.094 175.957 296.645 176.693 297.38 176.877L314.641 181.293C315.559 181.477 315.926 180.189 315.926 179.269L319.966 116.709C319.966 115.973 320.517 115.237 321.251 115.237L325.842 114.501C326.393 114.501 326.76 114.317 327.311 114.317C328.229 114.133 328.964 113.765 329.698 113.213L340.532 107.141C341.634 106.405 341.818 104.749 340.899 103.829Z"
        fill="#FFAF87"
      />
      <path
        d="M315.557 179.269L318.128 129.037L306.743 125.725L296.276 179.269C295.175 187.733 295.358 193.253 303.621 194.541C311.15 194.357 315.741 186.997 315.557 179.269Z"
        fill="#FFAF87"
      />
      <path
        d="M266.896 118.733C266.896 118.733 273.69 111.925 313.905 181.109L299.214 193.253L259.184 137.317L266.896 118.733Z"
        fill="#FFAF87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M280.301 125.909C271.854 116.157 265 117.5 265 117.5L265 117.5L228.702 121.309L201.5 123C201.5 123 193.995 123.517 188.119 135.109C180.407 150.381 176.367 162.525 176.367 162.525L200.289 174.079C204.553 188.891 208.672 203.989 208.87 205.581C209.33 209.843 208.854 216.125 208.434 221.672L208.434 221.672C208.183 224.985 207.952 228.035 207.952 230.237L271.855 226.189C271.498 223.328 270.577 219.467 269.619 215.452C268.607 211.204 267.552 206.783 267.08 203.189C266.713 200.061 266.713 197.117 267.264 193.989C267.908 190.225 268.803 184.528 269.806 178.139L269.807 178.138C270.602 173.074 271.466 167.575 272.327 162.258L276.629 167.861L298.48 149.645C298.48 149.645 291.503 138.973 280.301 125.909Z"
        fill="#73308A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M229.436 105.485C233.108 104.381 235.679 101.621 235.679 101.621L238.964 120.223L239.169 120.205L248.901 119.285C248.901 119.285 247.8 136.581 231.824 137.501C215.848 138.421 212.359 122.229 212.359 122.229L217.488 121.978L218.969 102.541L222.091 105.485C222.091 105.485 225.763 106.589 229.436 105.485Z"
        fill="#FFAF87"
      />
      <path d="M235.679 101.621L217.867 118.917L219.153 101.989L235.679 101.621Z" fill="#EB855A" />
      <path
        d="M215.481 78.0691C216.394 78.0691 217.133 77.3277 217.133 76.4131C217.133 75.4986 216.394 74.7571 215.481 74.7571C214.568 74.7571 213.828 75.4986 213.828 76.4131C213.828 77.3277 214.568 78.0691 215.481 78.0691Z"
        fill="#231815"
      />
      <path
        d="M200.24 70.7092C200.24 70.7092 201.342 101.253 214.93 105.669C226.499 109.533 235.13 105.117 238.802 102.173C242.475 99.2291 246.882 91.6851 246.698 78.9891C246.698 66.4772 246.698 66.4772 246.698 66.4772C246.698 66.4772 245.413 47.5252 237.149 44.9492C228.886 42.3732 205.933 43.1092 202.995 48.9972C200.24 55.0692 200.24 70.7092 200.24 70.7092Z"
        fill="#FFAF87"
      />
      <path
        d="M213.278 77.3331C214.19 77.3331 214.93 76.5916 214.93 75.6771C214.93 74.7625 214.19 74.0211 213.278 74.0211C212.365 74.0211 211.625 74.7625 211.625 75.6771C211.625 76.5916 212.365 77.3331 213.278 77.3331Z"
        fill="#231815"
      />
      <path
        d="M229.252 76.0453C230.165 76.0453 230.905 75.3039 230.905 74.3893C230.905 73.4747 230.165 72.7333 229.252 72.7333C228.34 72.7333 227.6 73.4747 227.6 74.3893C227.6 75.3039 228.34 76.0453 229.252 76.0453Z"
        fill="#231815"
      />
      <path
        opacity="0.37"
        d="M221.357 78.8052C221.357 78.8052 219.521 83.4052 220.255 85.0612C220.806 86.7172 222.092 86.5332 222.092 86.5332C222.092 86.5332 223.377 86.5332 223.561 84.6932C223.928 83.0372 221.357 78.8052 221.357 78.8052Z"
        fill="#F8895D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M218.787 94.2612L218.429 93.7183C218.129 93.9157 218.046 94.3188 218.244 94.6186C218.441 94.9184 218.844 95.0014 219.144 94.8042L219.147 94.8023L219.159 94.7947L219.209 94.7626C219.253 94.7341 219.321 94.6918 219.408 94.6384C219.583 94.5316 219.837 94.3804 220.152 94.2058C220.784 93.8557 221.651 93.4162 222.601 93.0509C223.556 92.6835 224.565 92.4032 225.488 92.3484C226.411 92.2935 227.179 92.4669 227.741 92.9252C228.02 93.1519 228.429 93.1099 228.656 92.8315C228.883 92.5532 228.841 92.1437 228.562 91.9171C227.656 91.1794 226.519 90.9848 225.41 91.0507C224.302 91.1166 223.153 91.4458 222.134 91.8375C221.11 92.2311 220.187 92.7001 219.522 93.0688C219.188 93.2536 218.918 93.4143 218.729 93.5296C218.635 93.5872 218.562 93.6335 218.511 93.6658C218.485 93.682 218.466 93.6947 218.452 93.7035L218.436 93.7139L218.431 93.7169L218.429 93.7183C218.429 93.7184 218.429 93.7183 218.787 94.2612Z"
        fill="#231815"
      />
      <path
        opacity="0.5"
        d="M213.68 92.2567C213.313 88.3927 210.007 85.6327 206.335 86.0007C204.866 86.1847 203.397 86.7367 202.479 87.6567C203.58 91.8887 205.233 96.3047 207.436 99.6167H207.62C211.293 99.4327 214.047 96.1207 213.68 92.2567Z"
        fill="#FF7878"
        fillOpacity="0.35"
      />
      <path
        opacity="0.5"
        d="M242.657 82.6694C238.801 83.0374 236.046 86.3494 236.414 90.0294C236.781 93.7094 239.719 96.2854 243.208 96.2854C244.861 93.1574 246.146 88.9254 246.513 83.5894C245.411 82.8534 244.126 82.4854 242.657 82.6694Z"
        fill="#FF7878"
        fillOpacity="0.35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M252.5 78.35C252.859 78.35 253.15 78.641 253.15 79C253.15 79.6598 252.879 80.5897 252.421 81.4327C251.96 82.2797 251.246 83.1575 250.264 83.594C249.936 83.7398 249.552 83.592 249.406 83.264C249.26 82.9359 249.408 82.5518 249.736 82.406C250.354 82.1313 250.889 81.5277 251.279 80.8117C251.67 80.0918 251.85 79.3772 251.85 79C251.85 78.641 252.141 78.35 252.5 78.35Z"
        fill="#EB855A"
      />
      <path
        d="M198.587 71.4454C198.587 71.4454 200.607 70.3414 205.014 69.6054L209.054 55.2534C209.054 55.2534 207.034 65.5574 206.483 69.2374C208.32 68.8694 210.523 68.3174 212.91 67.7654C214.012 62.7974 216.032 53.4134 215.481 48.2614C215.665 49.7334 217.317 59.8534 217.317 66.8454C218.603 66.6614 219.888 66.4774 221.357 66.4774C224.479 66.2934 230.722 66.4774 236.598 66.6614C236.966 62.4294 237.516 53.9654 236.415 49.1814C236.782 50.2854 240.087 59.4854 240.638 66.6614C245.963 66.8454 250.187 67.0294 250.187 67.0294C250.187 67.0294 250.921 55.2534 242.658 44.2135C234.395 32.9895 216.399 36.4855 216.399 36.4855C216.399 36.4855 209.054 36.6695 202.994 44.2135C197.302 51.7574 198.587 71.4454 198.587 71.4454Z"
        fill="#231815"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M225.627 68.0777C225.627 68.0777 225.627 68.0777 225.627 68.0777L225.629 68.0785L225.639 68.0821L225.68 68.0974C225.718 68.1111 225.774 68.1314 225.847 68.1572C225.994 68.2087 226.209 68.282 226.479 68.3675C227.019 68.5386 227.775 68.7572 228.635 68.9476C230.384 69.3344 232.458 69.579 234.045 69.1607C234.392 69.0692 234.747 69.2764 234.839 69.6235C234.93 69.9706 234.723 70.3262 234.376 70.4178C232.473 70.9195 230.141 70.6121 228.354 70.2169C227.448 70.0163 226.654 69.7865 226.087 69.6068C225.803 69.5169 225.574 69.4392 225.416 69.3837C225.337 69.3559 225.276 69.3337 225.233 69.3181L225.184 69.3L225.171 69.295L225.167 69.2936L225.166 69.2931C225.166 69.2931 225.165 69.2929 225.396 68.6853L225.166 69.2931C224.83 69.1656 224.661 68.7899 224.789 68.4543C224.916 68.1188 225.292 67.9503 225.627 68.0777Z"
        fill="#231815"
      />
    </g>
    <path
      d="M419.634 273.812L441.627 285.18L435.391 288.573L443.418 298.064L437.44 303.331L429.85 293.128L424.778 298.85L419.634 273.812Z"
      fill="#C186D5"
    />
    <circle cx="398.691" cy="180.691" r="28.6906" fill="#C186D5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M384 177.783C384 183.724 388.841 188.345 394.783 188.345C397.292 188.345 399.566 187.521 401.373 186.121C401.377 186.125 401.38 186.129 401.384 186.133L410.824 195.572C411.296 196.045 412.063 196.045 412.535 195.572C413.008 195.1 413.008 194.333 412.535 193.861L403.104 184.429C404.57 182.629 405.471 180.327 405.565 177.783C405.565 171.841 400.724 167 394.783 167C388.841 167 384 171.841 384 177.783ZM386.421 177.783C386.421 173.162 390.162 169.421 394.783 169.421C399.184 169.421 402.925 173.162 403.145 177.783C403.145 182.404 399.404 186.145 394.783 186.145C390.162 186.145 386.421 182.404 386.421 177.783Z"
      fill="#FEFEF4"
    />
    <path
      d="M367.416 249.504H363.781C362.926 249.504 362.07 248.648 362.07 247.792V244.582C362.07 243.726 362.926 242.87 363.781 242.87H367.416C368.272 242.87 369.127 243.726 369.127 244.582V247.792C369.127 248.862 368.272 249.504 367.416 249.504Z"
      fill="#73308A"
    />
    <path
      d="M134.668 6.63426H131.033C130.178 6.63426 129.322 5.77822 129.322 4.92219V1.71207C129.322 0.856039 130.178 0 131.033 0H134.668C135.523 0 136.379 0.856039 136.379 1.71207V4.92219C136.379 5.99223 135.523 6.63426 134.668 6.63426Z"
      fill="#73308A"
    />
    <path
      d="M20.3459 152.196H16.7107C15.8553 152.196 15 151.34 15 150.484V147.274C15 146.418 15.8553 145.562 16.7107 145.562H20.3459C21.2012 145.562 22.0566 146.418 22.0566 147.274V150.484C22.0566 151.554 21.2012 152.196 20.3459 152.196Z"
      fill="#73308A"
    />
    <path
      d="M64.5034 83.1326C67.6069 77.9324 75.139 77.9324 78.2425 83.1326L99.8688 119.369C103.051 124.701 99.2092 131.469 92.9992 131.469H49.7467C43.5367 131.469 39.6946 124.701 42.8771 119.369L64.5034 83.1326Z"
      fill="#EC726B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.2775 94C68.8401 94 66.8954 96.0336 67.0044 98.4686L67.5392 110.425C67.6286 112.425 69.2759 114 71.2775 114C73.2792 114 74.9264 112.425 75.0158 110.425L75.5507 98.4686C75.6596 96.0336 73.7149 94 71.2775 94ZM74.8543 120.364C74.8543 122.372 73.2522 124 71.2759 124C69.2996 124 67.6975 122.372 67.6975 120.364C67.6975 118.355 69.2996 116.727 71.2759 116.727C73.2522 116.727 74.8543 118.355 74.8543 120.364Z"
      fill="white"
    />
    <defs>
      <clipPath id="clip0">
        <rect
          width="221.455"
          height="295.871"
          fill="white"
          transform="translate(119.994 7.96533)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default MainGirlConfused;
