/* eslint-disable max-len */

import React from 'react';

export const SvgStar = () => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
      fill="#3fc078"
    />
    <path
      d="M22.8815 13.7122L18.4777 13.2839L16.7254 9.02457C16.6035 8.72749 16.1984 8.72749 16.0765 9.02457L14.3275 13.2873L9.92045 13.7122C9.61083 13.7433 9.48896 14.144 9.71953 14.3582L13.0429 17.4188L12.0713 21.9475C12.0054 22.2653 12.3315 22.514 12.5983 22.3482L16.4026 19.9785L20.2069 22.3482C20.4737 22.514 20.7998 22.2653 20.7306 21.9475L19.759 17.4222L23.0791 14.3616C23.3129 14.1475 23.1878 13.7433 22.8815 13.7122Z"
      fill="#ffffff"
    />
  </svg>
);
