import React from 'react';
import _get from 'lodash/get';

export const FeatureContext = React.createContext({
  features: [],
  isReady: false,
});

export const useFeatures = () => React.useContext(FeatureContext);

export const FeatureContextProvider = ({ features, isReady, children, ...rest }) => {
  const isFlagEnabled = React.useCallback(
    (flagName) => isReady && Boolean(_get(features, [flagName, 'treatment'], 'off') === 'on'),
    [isReady, features],
  );

  // flag config could be in any format, not necessarily JSON
  const getFlagConfig = React.useCallback(
    (flagName) => (isReady ? features[flagName].config : null),
    [isReady, features],
  );

  return (
    <FeatureContext.Provider value={{ features, isReady, isFlagEnabled, getFlagConfig, ...rest }}>
      {children}
    </FeatureContext.Provider>
  );
};
