import React from 'react';
import _get from 'lodash/get';

import DynamicFeatureProvider, { setupSplitConfig } from '../../components/DynamicFeatureProvider';
import DynamicUserProvider from '../../components/DynamicUserProvider';

const Account = ({ user: remoteUser, loading, refreshUserData, children, ...rest }) => {
  const [localUser, setLocalUser] = React.useState(); // local user entity

  const getSplitConfig = React.useCallback(() => {
    const uid = _get(localUser, 'uid', 'anonymous');
    return setupSplitConfig(uid);
  }, [localUser]);

  React.useEffect(() => {
    setLocalUser(remoteUser);

    return () => {
      setLocalUser(null);
    };
  }, [remoteUser]);

  const hasLinkAssociation = React.useMemo(
    () => _get(localUser, ['association', 'linkplus'], false),
    [localUser],
  );

  return (
    <DynamicUserProvider
      user={localUser}
      loading={loading}
      setUser={setLocalUser}
      refreshUserData={refreshUserData}
      lpConnected={hasLinkAssociation}
      {...rest}
    >
      <DynamicFeatureProvider getConfig={getSplitConfig}>{children}</DynamicFeatureProvider>
    </DynamicUserProvider>
  );
};

export default Account;
