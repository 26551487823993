import React from 'react';
import SvgArrowRight from '@ntuctech/devex-tangram/Icon/SvgArrowRight';
import SvgLoading from '@ntuctech/devex-tangram/Icon/SvgLoading';
import SvgLocation from '@ntuctech/devex-tangram/Icon/SvgLocation';
import SvgSearch from '@ntuctech/devex-tangram/Icon/SvgSearch';
import Typography from '@ntuctech/devex-tangram/Typography';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import { fetcherApiRoute } from './api/fetcher';

const ResultItemWrapper = styled.div`
  display: flex;

  & .result-text-content {
    max-width: calc(100% - 3rem);
    overflow: hidden;
  }

  & .result-text-content > * {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &:hover .right-arrow-icon {
    display: block;
  }

  & .search-icon,
  & .location-icon {
    width: 1rem;
    min-width: 1rem;
    height: 1rem;
    margin-top: 0.25rem;
    margin-right: 0.5rem;
  }
`;

const callSearchApi = async ({ term }, successCallback, errorCallback) => {
  try {
    const result = await fetcherApiRoute(`/api/onemap-address`, {
      method: 'POST',
      body: JSON.stringify({
        term,
      }),
    });

    const results = _get(result, 'results', []);
    typeof successCallback === 'function' && successCallback(results);
  } catch (e) {
    typeof errorCallback === 'function' && errorCallback();
  }
};

const getFromSearchResult = (suggestion, key) => {
  const rawValue = _get(suggestion, key);
  if (rawValue === 'NIL') {
    return '';
  }
  return rawValue;
};

const blockAndStreet = (suggestion) => {
  return `${getFromSearchResult(suggestion, 'BLK_NO')} ${getFromSearchResult(
    suggestion,
    'ROAD_NAME',
  )}`;
};

const fillFormWithSearchResult = (
  suggestion,
  resetForm,
  setFieldValue,
  setCanSearch,
  setShowResult,
  setShowFullForm,
  updateGeoData,
) => {
  resetForm();

  setFieldValue('postalCode', getFromSearchResult(suggestion, 'POSTAL'));
  setFieldValue('blockNumber', getFromSearchResult(suggestion, 'BLK_NO'));
  setFieldValue('roadName', getFromSearchResult(suggestion, 'ROAD_NAME'));
  setFieldValue('buildingName', getFromSearchResult(suggestion, 'BUILDING'));

  // disable search as we are filling postal code from search result
  setCanSearch(false);
  setShowResult(false);

  if (updateGeoData) {
    setShowFullForm(true);
    updateGeoData({
      latitude: getFromSearchResult(suggestion, 'LATITUDE'),
      longitude: getFromSearchResult(suggestion, 'LONGITUDE'),
    });
  }
};

const ResultItem = ({ suggestion, onClick, ...rest }) => {
  const noResult = _isEmpty(suggestion);
  if (noResult) {
    return (
      <ResultItemWrapper
        data-testid="address-selection"
        onClick={() => {
          onClick(suggestion);
        }}
        {...rest}
      >
        <SvgSearch className="search-icon" color="#666666" />
        <div>
          <Typography variant="subhead1" className="main-text">
            Didn&#39;t see your address here?
          </Typography>
          <Typography variant="body4" className="secondary-text">
            Click to enter manually
          </Typography>
        </div>
        <SvgArrowRight className="right-arrow-icon" color="#73308A" />
      </ResultItemWrapper>
    );
  }
  // first line: building name, OR, block number + street name
  const firstLine = getFromSearchResult(suggestion, 'BUILDING') || blockAndStreet(suggestion);
  // second line: block number + street name, AND, Singapore + postal code
  const secondLine = `${blockAndStreet(suggestion)},  Singapore ${getFromSearchResult(
    suggestion,
    'POSTAL',
  )}`;
  return (
    <ResultItemWrapper
      data-testid="address-selection"
      onClick={() => {
        onClick(suggestion);
      }}
      {...rest}
    >
      <SvgLocation className="location-icon" color="#333333" />
      <div className="result-text-content">
        <Typography variant="subhead1" className="main-text" data-testid="address-selection-line1">
          {firstLine}
        </Typography>
        <Typography
          variant="body4"
          className="secondary-text"
          data-testid="address-selection-line2"
        >
          {secondLine}
        </Typography>
      </div>
      <SvgArrowRight className="right-arrow-icon" color="#73308A" />
    </ResultItemWrapper>
  );
};

// eslint-disable-next-line react-hooks/exhaustive-deps
const debouncedSearch = _debounce(callSearchApi, 500, { leading: false, trailing: true });
export const searchAddress = (byTerm, setSuggestions) => {
  debouncedSearch(
    { term: byTerm },
    (addresses) => {
      setSuggestions(addresses);
    },
    () => {
      setSuggestions([]);
    },
  );
};

export const handleAddressInputChange = (e, setCanSearch, setSearchTerm, handleFormikChange) => {
  if (e.target.name === 'postalCode') {
    setCanSearch(true);
    if (e.target.value.length <= 6) {
      setSearchTerm(e.target.value);
    } else {
      return;
    }
  }
  handleFormikChange(e);
};

export const renderSearchResult = ({
  loading,
  suggestions,
  resetForm,
  setFieldValue,
  setCanSearch,
  setShowResult,
  searchTerm,
  enterManually,
  setShowFullForm,
  updateGeoData,
}) => {
  if (loading) {
    return (
      <div className="suggestion-item" style={{ textAlign: 'center' }}>
        <SvgLoading size="l" color="#666666" />
      </div>
    );
  }
  if (suggestions.length > 0) {
    return suggestions
      .slice(0, 5)
      .map((suggestion, index) => (
        <ResultItem
          key={index}
          suggestion={suggestion}
          onClick={(sugg) =>
            fillFormWithSearchResult(
              sugg,
              resetForm,
              setFieldValue,
              setCanSearch,
              setShowResult,
              setShowFullForm,
              updateGeoData,
            )
          }
          className="suggestion-item"
        />
      ));
  }
  if (searchTerm.length === 6) {
    return <ResultItem suggestion={{}} onClick={enterManually} className="suggestion-item" />;
  }
  return null;
};
