import React from 'react';
import Box from '@ntuctech/devex-tangram/Box';
import ButtonIcon from '@ntuctech/devex-tangram/Button/ButtonIcon';
import SvgClose from '@ntuctech/devex-tangram/Icon/SvgClose';
import Link from '@ntuctech/devex-tangram/Link';
import Backdrop from '@ntuctech/devex-tangram/Modal/BackDrop';
import Typography from '@ntuctech/devex-tangram/Typography';
import styled from 'styled-components';

import { useUser } from '../../services/profile';
import Endorsement from '../Endorsement';
import PrivacyNoticeLink from '../PrivacyNoticeLink';
import { Separator } from '../Theme';

import NavLink from './NavLink';

const MobileNavigationBackDrop = styled(Backdrop)`
  @keyframes mobileNav__BackDrop--Open__kf {
    from {
      opacity: 0.8;
    }
    to {
      opacity: 1;
    }
  }

  animation: mobileNav__BackDrop--Open__kf ease-in-out 0.3s;
`;

const MobileNavigationSC = styled(Box)`
  position: fixed;
  width: 305px;
  right: 0;
  top: 0;
  height: 100vh;
  background: white;
  padding: 3.625rem 1rem 1.5rem 2rem;

  .endorsement {
    position: fixed;
    bottom: 0;
    margin-bottom: 2rem;
  }

  @keyframes mobileNav__Open__kf {
    from {
      opacity: 0.8;
    }
    to {
      opacity: 1;
    }
  }
  animation: mobileNav__Open__kf cubic-bezier(0.42, 0, 0.58, 1) 0.3s;
`;

const LogoutLink = styled(Link)`
  color: ${(props) => props.theme.ColorFontError};

  &:hover,
  &:active,
  &:focus {
    box-shadow: 0 0.0625rem ${(props) => props.theme.ColorFontError};
  }
`;

const ButtonCloseNav = styled(ButtonIcon)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

const NavText = styled(Typography)`
  word-break: break-word;
  overflow-wrap: break-word;
`;

function MobileNavigation({ open, onClose, onLogout }) {
  const { user: localUser, loading, userAuthenticatedByM2m, lpConnected } = useUser();

  const { name = '', phone_number: phoneNumber = '', email = '', country_code } = localUser || {};
  const isPhoneEmpty = phoneNumber === '00000000' || phoneNumber === '';

  const handleNavClick = (e) => {
    e.stopPropagation();
  };

  return open ? (
    <MobileNavigationBackDrop
      open={open}
      className="ndsHidden-m-up"
      onClick={onClose}
      data-testid="mobile-navigation"
    >
      <MobileNavigationSC
        onClick={handleNavClick}
        data-testid="mobile-navigation-content"
        data-dd-action-name="Close Menu"
      >
        <ButtonCloseNav onClick={onClose} compact data-testid="mobile-navigation-close">
          <SvgClose />
        </ButtonCloseNav>

        {!loading && !userAuthenticatedByM2m && (
          <>
            <NavText variant="h6">{name}</NavText>
            <Box mt={0.5}>
              <NavText>{email}</NavText>
            </Box>
            {!isPhoneEmpty && (
              <Box mt={2}>
                <NavText>
                  +{country_code} {phoneNumber}
                </NavText>
              </Box>
            )}
          </>
        )}

        <Box mt={2}>
          <Separator />
        </Box>
        <Box mt={3}>
          <NavLink href="/">Profile</NavLink>
        </Box>
        {lpConnected && (
          <Box mt={3}>
            <NavLink href="/linkpoints">Linkpoints</NavLink>
          </Box>
        )}
        <Box mt={3}>
          <NavLink href="/notifications">Notifications</NavLink>
        </Box>
        <Box mt={3}>
          <NavLink href="/settings">Settings</NavLink>
        </Box>
        <Box mt={3}>
          <Separator />
        </Box>
        <Box mt={3}>
          <PrivacyNoticeLink />
        </Box>

        {!userAuthenticatedByM2m && (
          <Box mt={3}>
            <LogoutLink
              data-testid="mobile-SignOutLink"
              onClick={onLogout}
              title="Log out"
              aria-label="Log out"
              size="l"
            >
              Log out
            </LogoutLink>
          </Box>
        )}
        <Endorsement className={'endorsement'} />
      </MobileNavigationSC>
    </MobileNavigationBackDrop>
  ) : null;
}

export default MobileNavigation;
