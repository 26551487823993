import React from 'react';
import Box from '@ntuctech/devex-tangram/Box';
import SvgVerified from '@ntuctech/devex-tangram/Icon/SvgVerified';

const VerifiedBadge = (props) => {
  return (
    <>
      <Box ml={1} />
      <SvgVerified size="s" color="#4787EB" {...props} />
    </>
  );
};

export { VerifiedBadge };
