export const partyMapper = {
  cdg_zig: 'CDG Zig',
  health_365: 'Healthy 365',
};

export const displayPathMapper = (currPath, isEmailVerified, isPhoneVerified, externalParty) => {
  const pathAndDisplayMap = {
    // empty string to represent profile page
    '': 'Link profile',
    settings: 'Settings',
    'account-termination': 'Delete your Linkpass account',
    notifications: 'Notifications',
    linkpoints: 'Linkpoints',
    'transaction-history': 'Linkpoints history',
    restore: 'Connect Link account',
    cards: 'View cards',
    mfa: 'Multi-factor authentication',
    'verify-mobile': 'Edit mobile number',
    'verify-email': 'Edit email',
    'connected-accounts': 'Connected accounts',
  };

  switch (true) {
    case currPath === 'verify-mobile' && !isPhoneVerified:
      return 'Edit and verify mobile number';
    case currPath === 'verify-email' && !isEmailVerified:
      return 'Edit and verify email';
    case currPath === 'connected-party':
      return partyMapper[externalParty];
    default:
      return pathAndDisplayMap[currPath];
  }
};

export const getDisplayPath = (isEmailVerified, isPhoneVerified, externalParty) => {
  const getCurrPathArray = window.location.pathname.split('/');
  let pathArray = getCurrPathArray.slice(1, getCurrPathArray.length);
  let displayPath = [];
  for (const path of pathArray) {
    displayPath.push(displayPathMapper(path, isEmailVerified, isPhoneVerified, externalParty));
  }

  const lastPath = pathArray[pathArray.length - 1];
  // '' - empty string represents profile page
  if (lastPath === 'verify-mobile' || lastPath === 'verify-email') {
    displayPath = ['Link profile', ...displayPath];
    pathArray = ['', ...pathArray];
  }

  return {
    displayPath,
    routerPath: pathArray,
  };
};

export const getReferrerEntity = (referrer) => {
  // only have fp domain for now, cases for
  // ntuc union portal and nfc to be confirmed
  if (referrer.includes('fairprice')) {
    return 'FairPrice account';
  }
  return '';
};

const regex = /^(www|preprod|zs-uat|preuat).fairprice.com.sg$/;

// list of allowed entry points of SAM
// used to whitelist referrer (full URL with protocol and trailing slash)
// from either document.referrer (client-side)
// or request headers (server-side)
const allowedEntryPoints = [
  'https://www.fairprice.com.sg/',
  'https://preprod.fairprice.com.sg/',
  'https://preuat.fairprice.com.sg/',
  'https://zs-uat.fairprice.com.sg/',
  'https://www.ntuc.org.sg/',
];
const isReferrerDomainAllowed = (domain) => domain.match(regex);

// get and sanitize external referrer on client-side with document.referrer
// if relayedReferrerFromAuth0 not empty
// this function acts as a sanitizer for the value
const getCrossDomainReferrer = (relayedReferrerFromAuth0 = '', isNudgeFlow = false) => {
  const parser = document.createElement('a');

  // document.referrer could be empty if user navigates within the same Next.js app
  // but it's not a concern as we are only interested in cross-domain referrers
  parser.href = relayedReferrerFromAuth0 || document.referrer;

  // the current site domain (SAM) will never match the regex
  // i.e., the referrer link will be cross-domain only
  if (isReferrerDomainAllowed(parser.host)) {
    const referrerDomain = `${parser.protocol}//${parser.host}`;
    /* eslint-disable */
    if (isNudgeFlow === 'matching_record' || !isNudgeFlow) {
      return `${referrerDomain}/accounts`;
    } else {
      return referrerDomain;
    }
  }
  return '';
};

// get and sanitize external referrer on server-side with request headers
export const sanitizeExternalBaseUrl = (requestReferrerDomain) =>
  allowedEntryPoints.includes(requestReferrerDomain) ? requestReferrerDomain : '';

export default getCrossDomainReferrer;
