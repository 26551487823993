import React from 'react';
import styled from 'styled-components';

const ProfileContentSC = styled.div`
  flex: 1 1 auto;

  &.accTextOverflow {
    overflow: hidden;
  }

  &.accTextOverflow p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ProfileContent = (props) => {
  const { children, ...rest } = props;
  return <ProfileContentSC {...rest}>{children}</ProfileContentSC>;
};

export default ProfileContent;
