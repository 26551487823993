import React from 'react';
import Box from '@ntuctech/devex-tangram/Box';
import ButtonContained from '@ntuctech/devex-tangram/Button/ButtonContained';
import Input from '@ntuctech/devex-tangram/Input';
import { Formik } from 'formik';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _unset from 'lodash/unset';
import styled from 'styled-components';
import { object as YupObject } from 'yup';

import {
  handleAddressInputChange,
  renderSearchResult,
  searchAddress,
} from '../../services/address-search-utils';
import { fetcherApiRoute } from '../../services/api/fetcher';
import { addressValidation } from '../../services/input-validator';
import Logger from '../../services/logger';
import FormAction from '../FormAction';
import FormWrapper from '../FormWrapper';
import FullAddressForm from '../FullAddressForm';
import ProfileAction from '../ProfileAction';
import ProfileContent from '../ProfileContent';
import ProfileRow from '../ProfileRow';
import ProfileTitle from '../ProfileTitle';

const STYLED = {};

STYLED.SearchPostalCode = styled.div`
  width: 100%;
  position: relative;

  & svg {
    width: 1.5rem;
    position: absolute;
    top: 2.25rem;
    right: 0.75rem;
    cursor: pointer;
  }
`;

STYLED.SearchResultDropdown = styled.div`
  position: relative;
  top: -1.25rem;

  @media (max-width: 767px) {
    top: -0.75rem;
  }
`;

STYLED.ResultListWrapper = styled.div`
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 0.25rem;
  width: 100%;
  max-height: 12rem;
  padding: 0 1rem;
  position: absolute;
  top: 0;
  z-index: 1;
  background: white;
  overflow: auto;
  margin-bottom: 2rem;

  & .recent-search {
    margin-top: 2.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .suggestion-item {
    cursor: pointer;
    padding: 1rem 0;
    border-bottom: 1px solid #d8d8d8;
    position: relative;
  }

  & .suggestion-item-bg {
    width: calc(100% + 2rem);
    height: 100%;
    position: absolute;
    z-index: 2;
    left: -1rem;
    top: 0;
    will-change: background-color;
    transition: background-color ease 0.3s;
  }

  & .right-arrow-icon {
    display: none;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  & .main-text {
    color: #000000;
    position: relative;
    z-index: 3;
  }

  & .secondary-text {
    color: #666666;
    position: relative;
    z-index: 3;
  }

  & > div:last-child {
    border-bottom: none;
  }
`;

const FormContent = (props) => {
  const {
    closeForm,
    mode,
    updateGeoData,
    // Formik props
    values,
    errors,
    handleChange: handleFormikChange,
    handleBlur,
    initialValues,
    isSubmitting,
    resetForm,
    submitForm,
    setFieldValue,
    isValid,
  } = props;

  // data states
  const [searchTerm, setSearchTerm] = React.useState('');
  const [suggestions, setSuggestions] = React.useState([]);

  // UI states
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [showResult, setShowResult] = React.useState(false);
  const [showFullForm, setShowFullForm] = React.useState(false);
  const [canSearch, setCanSearch] = React.useState(true);

  const enterManually = React.useCallback(() => {
    resetForm();

    setFieldValue('hasNoFloorUnit', false);
    setFieldValue('postalCode', searchTerm);

    updateGeoData({});
    setShowResult(false);
    setShowFullForm(true);
  }, [resetForm, searchTerm, setFieldValue, updateGeoData]);

  // custom isDirty() method
  // because Formik's dirty prop is always true
  // after hasNoFloorUnit is updated
  const isDirty = React.useCallback(() => {
    const v = { ...values };
    const i = { ...initialValues };
    _unset(v, 'hasNoFloorUnit');
    _unset(i, 'hasNoFloorUnit');
    const r = _isEqual(v, i);

    return !r;
  }, [values, initialValues]);

  React.useEffect(() => {
    if (!canSearch) {
      return;
    }

    const term = searchTerm.trim();

    if (term.length >= 4) {
      // trigger search
      setLoading(true);
      searchAddress(term, setSuggestions);
      setLoading(false);
      setShowResult(true);
    } else {
      setShowResult(false);
      if (term.length === 0) {
        setShowFullForm(false);
        updateGeoData({});
      }
    }
  }, [searchTerm, canSearch, updateGeoData]);

  const {
    postalCode,
    blockNumber,
    roadName,
    buildingName,
    hasNoFloorUnit,
    floorNumber,
    unitNumber,
  } = values;

  React.useEffect(() => {
    // we are not adding validation on
    // postal code field
    // because "use my current location"
    // will blur the field and trigger
    // unwanted validation
    // but here we rely on this check to
    // enable/disable submit CTA
    const isPostalCodeInvalid = typeof postalCode === 'undefined' || postalCode.length < 6;

    // enable/disable submit CTA
    const shouldDisableSubmitButton = !isValid || !isDirty() || isPostalCodeInvalid;

    setIsSubmitButtonDisabled(shouldDisableSubmitButton);
  }, [isValid, values, isSubmitting, postalCode, isDirty]);

  React.useEffect(() => {
    if (hasNoFloorUnit) {
      setFieldValue('floorNumber', '');
      setFieldValue('unitNumber', '');
    }
  }, [hasNoFloorUnit, setFieldValue]);

  React.useEffect(() => {
    if (mode === 'edit') {
      setCanSearch(false);
      setSearchTerm(postalCode);
      setShowResult(false);
      setShowFullForm(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const showPostalCodeError = Boolean(errors.postalCode);
  const showBlockNumberError = Boolean(errors.blockNumber);
  const showRoadNameError = Boolean(errors.roadName);
  const showFloorError = Boolean(errors.floorNumber);
  const showUnitError = Boolean(errors.unitNumber);

  const hasFloorUnitError = showFloorError || showUnitError;

  return (
    <Box mt={3}>
      <FormWrapper>
        <ProfileRow>
          <ProfileTitle>Residing address</ProfileTitle>
          <ProfileContent>
            <STYLED.SearchPostalCode>
              <Input
                data-testid="PostalCodeInput"
                type="tel"
                name="postalCode"
                label="Postal code"
                onChange={(e) =>
                  handleAddressInputChange(e, setCanSearch, setSearchTerm, handleFormikChange)
                }
                onBlur={handleBlur}
                required
                fullWidth
                value={postalCode}
                error={showPostalCodeError}
              />
            </STYLED.SearchPostalCode>
            {showResult && (
              <STYLED.SearchResultDropdown>
                <STYLED.ResultListWrapper
                  data-testid="AddressResultListWrapper"
                  data-dd-action-name="Select/Close Postal Code"
                >
                  {renderSearchResult({
                    loading,
                    suggestions,
                    resetForm,
                    setFieldValue,
                    setCanSearch,
                    setShowResult,
                    searchTerm,
                    enterManually,
                    setShowFullForm,
                    updateGeoData,
                  })}
                </STYLED.ResultListWrapper>
              </STYLED.SearchResultDropdown>
            )}
            {showFullForm && (
              <FullAddressForm
                handleChange={(e) =>
                  handleAddressInputChange(e, setCanSearch, setSearchTerm, handleFormikChange)
                }
                handleBlur={handleBlur}
                blockNumber={blockNumber}
                showBlockNumberError={showBlockNumberError}
                errors={errors}
                roadName={roadName}
                showRoadNameError={showRoadNameError}
                buildingName={buildingName}
                floorNumber={floorNumber}
                hasFloorUnitError={hasFloorUnitError}
                unitNumber={unitNumber}
                hasNoFloorUnit={hasNoFloorUnit}
              />
            )}
            <FormAction>
              <ButtonContained color="grey" data-testid="EditAddressCancel" onClick={closeForm}>
                Cancel
              </ButtonContained>
              {showFullForm && (
                <ButtonContained
                  data-testid="EditAddressCta"
                  onClick={submitForm}
                  disabled={isSubmitButtonDisabled}
                  loading={isSubmitting}
                >
                  {mode === 'edit' ? 'Save changes' : 'Add address'}
                </ButtonContained>
              )}
            </FormAction>
          </ProfileContent>
          <ProfileAction className="ndsHidden-s-down">
            <></>
          </ProfileAction>
        </ProfileRow>
      </FormWrapper>
    </Box>
  );
};

const EditAddressForm = (props) => {
  const { mode, initialFormData, handleCallback } = props;

  const [geoData, setGeoData] = React.useState({
    latitude: '',
    longitude: '',
  });

  // initialValues for Formik inputs
  // translated from SDK profile API response
  const initialValues = React.useMemo(() => {
    const floorNumber = _get(initialFormData, 'floor_number', '');
    const unitNumber = _get(initialFormData, 'unit_number', '');

    return {
      postalCode: _get(initialFormData, 'postal_code', ''),
      blockNumber: _get(initialFormData, 'block_number', ''),
      roadName: _get(initialFormData, 'road_name', ''),
      buildingName: _get(initialFormData, 'building_name', ''),
      floorNumber,
      unitNumber,
      hasNoFloorUnit: mode === 'edit' && !floorNumber && !unitNumber,
    };
  }, [mode, initialFormData]);

  // geoData are values that do not require customer inputs
  React.useEffect(
    () =>
      setGeoData({
        latitude: _get(initialFormData, 'latitude', ''),
        longitude: _get(initialFormData, 'longitude', ''),
      }),
    [initialFormData],
  );

  const AddressSchema = YupObject().shape({
    blockNumber: addressValidation.requiredValidation('block number'),
    roadName: addressValidation.requiredValidation('road name'),
    floorNumber: addressValidation.floorUnitValidation({
      fieldName: 'floor and unit number',
    }),
    unitNumber: addressValidation.floorUnitValidation({
      fieldName: 'floor and unit number',
    }),
  });

  const onSubmit = (values, actions) => {
    const dataForSubmission = {
      block_number: values.blockNumber,
      road_name: values.roadName,
      building_name: values.buildingName,
      postal_code: values.postalCode,
      floor_number: values.floorNumber,
      unit_number: values.unitNumber,
      latitude: geoData.latitude,
      longitude: geoData.longitude,
    };

    fetcherApiRoute('/api/address', {
      method: 'PUT',
      body: JSON.stringify(dataForSubmission),
    })
      .then((result) => {
        Logger.debug('[API][putAddress] update address result:', result);
        actions.setSubmitting(false);
        // only reset form when submission is successful
        actions.resetForm();
        handleCallback(false, { residential_address: result }, 'address');
      })
      .catch((error) => {
        Logger.debug('[API][putAddress] update address error:', error);
        actions.setSubmitting(false);
        handleCallback(error, {});
        // do not reset form when there is error
      })
      .finally(() => {
        // do not call setState here
        // because form might be closed
        // from handleCallback() above
        // and there will be error on
        // "set state on unmounted component"
      });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={AddressSchema}>
      {(formikProps) => (
        <FormContent
          {...formikProps}
          {...props}
          initialValues={initialValues}
          updateGeoData={setGeoData}
        />
      )}
    </Formik>
  );
};

export default EditAddressForm;
