import React from 'react';
import Box from '@ntuctech/devex-tangram/Box';
import SvgAccount from '@ntuctech/devex-tangram/Icon/SvgAccount';
import SvgLinkPlus from '@ntuctech/devex-tangram/Icon/SvgLinkPlus';
import SvgNotification from '@ntuctech/devex-tangram/Icon/SvgNotification';
import SvgSettings from '@ntuctech/devex-tangram/Icon/SvgSettings';
import styled from 'styled-components';

import { useUser } from '../../services/profile';
import Endorsement from '../Endorsement';
import PrivacyNoticeLink from '../PrivacyNoticeLink';
import { Separator } from '../Theme';

import NavLink from './NavLink';

const LeftNavigationSC = styled(Box)`
  position: fixed;
  left: 0px;
  width: 160px;

  @keyframes leftNav--Open__kf {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation: leftNav--Open__kf cubic-bezier(0.42, 0, 0.58, 1) 0.5s;
`;

function LeftNavigation() {
  const { lpConnected } = useUser();

  return (
    <LeftNavigationSC mt={8} className="ndsHidden-s-down">
      <NavLink href="/" data-testid="LeftNavProfile">
        <SvgAccount /> Profile
      </NavLink>
      {lpConnected && (
        <NavLink href="/linkpoints" data-testid="LeftNavLinkPoints">
          <SvgLinkPlus /> Linkpoints
        </NavLink>
      )}
      <NavLink href="/notifications" data-testid="LeftNavPreferences">
        <SvgNotification />
        Notifications
      </NavLink>
      <NavLink href="/settings" data-testid="LeftNavSettings">
        <SvgSettings />
        Settings
      </NavLink>
      <Box mt={5} mb={2}>
        <Separator />
      </Box>
      <Box pl={4}>
        <PrivacyNoticeLink />
      </Box>
      <Box pl={4} mt={4}>
        <Endorsement />
      </Box>
    </LeftNavigationSC>
  );
}

export default LeftNavigation;
