import React from 'react';
import ButtonContained from '@ntuctech/devex-tangram/Button/ButtonContained';

import FormAction from '../FormAction';

export default function HocFormContent(Component) {
  const WrappedComp = (compProps) => {
    const {
      errors,
      touched,
      isValid,
      dirty,
      isSubmitting,
      setIsEditing,
      closePrompt: closePromptProp,
      submitForm,
      onClose,
    } = compProps;

    // states
    const [showModal, setShowModal] = React.useState(false);

    // utility functions
    const showInlineErrorFor = (inputName) => touched[inputName] && Boolean(errors[inputName]);
    const showAlert = () => {
      setShowModal(true);
    };
    const hideAlert = () => {
      setShowModal(false);
    };
    const confirmSubmit = () => {
      submitForm();
    };

    // constants
    const isSubmitButtonDisabled = !dirty || !isValid || isSubmitting;

    // shared components
    const formActionFactory = React.useCallback(
      ({ name, action, ctaText }) => (
        <FormAction>
          <ButtonContained
            id={`Edit${name}CancelBtn`}
            data-testid={`Edit${name}CancelBtn`}
            color="grey"
            onClick={onClose}
          >
            Cancel
          </ButtonContained>
          <ButtonContained
            id={`Edit${name}SaveBtn`}
            data-testid={`Edit${name}SaveBtn`}
            disabled={isSubmitButtonDisabled}
            onClick={action}
          >
            {ctaText || 'Save changes'}
          </ButtonContained>
        </FormAction>
      ),
      [isSubmitButtonDisabled, onClose],
    );

    // effects
    React.useEffect(() => {
      setIsEditing(dirty && !isSubmitting);
      return () => {
        setIsEditing(false);
      };
    }, [setIsEditing, dirty, isSubmitting]);

    React.useEffect(() => {
      if (closePromptProp) {
        hideAlert();
      }
    }, [closePromptProp]);

    return (
      <Component
        showInlineErrorFor={showInlineErrorFor}
        isSubmitButtonDisabled={isSubmitButtonDisabled}
        isPreSubmitAlertShown={showModal}
        showPreSubmitAlert={showAlert}
        hidePreSubmitAlert={hideAlert}
        confirmSubmit={confirmSubmit}
        formActionFactory={formActionFactory}
        {...compProps}
      />
    );
  };

  // won't fix code smell
  return WrappedComp;
}
