import styled from 'styled-components';

import ProfileContent from './ProfileContent';

export { default } from './ProfileContent';

export const ProfileContentRow = styled(ProfileContent)`
  display: flex;
  align-items: center;
`;
