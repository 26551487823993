import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import _isNil from 'lodash/isNil';

import { PWL_CONNECTION_EMAIL, PWL_START_URL } from '../api/constants';
import { fetcherExternal } from '../api/fetcher';

import { getProfileCompletionStatus } from './profile-tasks';

/* Identity API: /passwordless/start (public) */
export const startPasswordless = async (
  connectionType,
  connectionValue,
  successCallback,
  errorCallback,
  finalCallback,
) => {
  const connectionValueObj =
    connectionType === PWL_CONNECTION_EMAIL
      ? { email: connectionValue }
      : { phone_number: `+65${connectionValue}` };

  try {
    await fetcherExternal(PWL_START_URL, {
      method: 'POST',
      body: JSON.stringify({
        client_id: process.env.AUTH0_CLIENT_ID,
        connection: connectionType,
        send: 'code',
        ...connectionValueObj,
      }),
      headers: { 'Content-Type': 'application/json' },
    });

    successCallback();
  } catch (e) {
    datadogRum.addError(e);
    errorCallback();
  } finally {
    finalCallback();
  }
};

/* set up user context
 * for components to get provider properties
 * without passing down props layer by layer */
const User = React.createContext({ user: null, loading: false });
export const useUser = () => React.useContext(User);

/* this is actually a constructor of a user provider component
 * it decouples the get-user logic from the provider component
 * to use in a page, first call useFetchUser() to get remoter user
 * then pass user as one of the value props to the provider component constructor
 * the pros: each individual page can has its own independent user provider
 * the cons: we have to set up the user provider on every page (code duplication) */
export const UserProvider = ({ value, children }) => {
  const { loading, user } = value;

  // to avoid racing condition when loading is false but user is null
  const userIsReady = React.useMemo(() => !loading && !_isNil(user), [loading, user]);

  // calculate status and tasks for profile completion
  const profileCompletionStatus = React.useMemo(
    () => getProfileCompletionStatus(userIsReady, user),
    [userIsReady, user],
  );

  return (
    <User.Provider value={{ userIsReady, profileCompletionStatus, ...value }}>
      {children}
    </User.Provider>
  );
};
