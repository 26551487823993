import React from 'react';

export const SvgSingPass = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 467 81"
    aria-labelledby="SingPassSvgTitle SingPassSvgDesc"
  >
    {/* title and desc are added */}
    {/* for accessibility */}
    {/* do not remove */}
    <title id="SingPassSvgTitle">SingPass verification</title>
    <desc id="SingPassSvgDesc">Verify your age with SingPass</desc>
    <path
      d="M443.776,59.7857 C458.235,59.7857 467,52.2575 467,40.5084 C467,32.2923 461.992,26.7066 451.629,24.4229
           L441.497,22.1391 C437.625,21.2256 435.577,19.9709 435.577,17.6926 C435.577,15.4143 437.51,13.9285 441.837,13.9285
           C447.076,13.9285 452.541,16.4379 456.297,19.8608 L464.836,7.31375 C460.74,3.6652 452.315,0.467905 441.837,0.467905
           C428.29,0.467905 419.185,7.19819 419.185,18.6061 C419.185,26.9323 423.852,32.9802 433.759,35.264 L443.891,37.6578
           C448.9,38.7969 450.492,40.2828 450.492,42.2199 C450.492,44.7293 448.444,46.0995 443.776,46.0995 C438.312,46.0995
           431.711,43.8158 426.357,39.2537 L417.817,51.8007 C421.804,55.4493 430.915,59.7857 443.776,59.7857 Z"
      fill="#F4333D"
    ></path>
    <path
      d="M389.816,59.7857 C404.275,59.7857 413.04,52.2575 413.04,40.5084 C413.04,32.2923 408.031,26.7066 397.669,24.4229
           L387.537,22.1391 C383.665,21.2256 381.617,19.9709 381.617,17.6926 C381.617,15.4143 383.55,13.9285 387.877,13.9285
           C393.116,13.9285 398.58,16.4379 402.336,19.8608 L410.876,7.31375 C406.779,3.6652 398.355,0.467905 387.877,0.467905
           C374.329,0.467905 365.224,7.19819 365.224,18.6061 C365.224,26.9323 369.892,32.9802 379.799,35.264 L389.931,37.6578
           C394.939,38.7969 396.532,40.2828 396.532,42.2199 C396.532,44.7293 394.483,46.0995 389.816,46.0995 C384.351,46.0995
           377.751,43.8158 372.396,39.2537 L363.857,51.8007 C367.844,55.4493 376.949,59.7857 389.816,59.7857 Z"
      fill="#F4333D"
    ></path>
    <path
      d="M319.792,0.462402 C327.535,0.462402 333.911,3.20294 338.123,9.01971 L338.123,5.59679 C338.123,3.08738 339.606,1.60154
          342.11,1.60154 L355.202,1.60154 L355.202,58.6411 L338.123,58.6411 L338.123,51.2284 C333.911,57.0452 327.535,59.7857
          319.792,59.7857 C304.992,59.7857 293.037,47.6954 293.037,30.1241 C293.037,12.5527 304.992,0.462402 319.792,0.462402 Z
          M324.35,15.5189 C316.382,15.5189 310.347,21.5668 310.347,30.1241 C310.347,38.6814 316.382,44.7293 324.35,44.7293
          C332.434,44.7238 338.694,38.6759 338.694,30.1241 C338.694,21.5668 332.434,15.5189 324.35,15.5189 Z"
      fill="#F4333D"
    ></path>
    <path
      d="M259.006,0.462402 C273.806,0.462402 285.755,12.5527 285.755,30.1241 C285.755,47.6954 273.8,59.7857 259,59.7857
          C251.257,59.7857 245.112,57.5019 240.669,52.3731 L240.669,79.6409 L223.59,79.6409 L223.59,1.60154 L236.688,1.60154
          C239.192,1.60154 240.675,3.08738 240.675,5.59679 L240.675,9.01971 C244.887,3.20294 251.263,0.462402 259.006,0.462402 Z
          M254.448,15.5189 C246.364,15.5189 240.104,21.5668 240.104,30.1241 C240.104,38.6814 246.364,44.7293 254.448,44.7293
          C262.421,44.7238 268.451,38.6759 268.451,30.1241 C268.451,21.5668 262.416,15.5189 254.448,15.5189 Z"
      fill="#F4333D"
    ></path>
    <path
      d="M176.692,0.462402 C183.98,0.462402 190.125,3.20294 193.996,9.01971 L193.996,5.59679 C193.996,3.08738 195.479,1.60154
          197.983,1.60154 L211.075,1.60154 L211.075,50.9973 C211.075,72.4428 197.187,81.0001 180.108,81.0001 C168.724,81.0001
          159.273,78.034 153.584,72.8996 L162.463,59.6647 C166.22,63.3132 172.255,66.6261 180.108,66.6261 C188.873,66.6261
          193.996,61.0349 193.996,53.2811 L193.996,49.6325 C189.899,54.7669 183.98,57.0452 176.692,57.0452 C162.919,57.0452
          150.964,44.9549 150.964,28.7538 C150.964,12.5527 162.919,0.462402 176.692,0.462402 Z M181.25,15.5189 C174.078,15.5189
          168.274,21.3356 168.274,28.7538 C168.274,36.1665 174.078,41.9887 181.25,41.9887 C188.988,41.9887 194.567,36.1665
          194.567,28.7538 C194.567,21.3411 188.988,15.5189 181.25,15.5189 Z"
      fill="#F4333D"
    ></path>
    <path
      d="M86.7508,58.6411 L103.83,58.6411 L103.83,27.9559 C103.83,20.4276 107.471,15.75 114.533,15.75 C121.249,15.75 124.78,19.6297
          124.78,27.9559 L124.78,58.6466 L141.859,58.6466 L141.859,23.6194 C141.859,8.33182 134.231,0.462402 121.139,0.462402
          C113.627,0.462402 107.932,3.42857 103.835,9.24534 L103.835,5.59679 C103.835,3.08738 102.358,1.60154 99.8482,1.60154
          L86.7563,1.60154 L86.7563,58.6411 L86.7508,58.6411 Z"
      fill="#F4333D"
    ></path>
    <path
      d="M66.031,19.8552 C71.6105,19.8552 75.9378,15.5187 75.9378,9.92758 C75.9378,4.33644 71.6105,0 66.031,0 C60.4515,0
          56.1242,4.33644 56.1242,9.92758 C56.1297,15.5187 60.4515,19.8552 66.031,19.8552 Z M55.3279,58.641 L76.7286,58.641
          L72.1761,23.1625 C68.7603,24.1916 63.2962,24.1916 59.8804,23.1625 L55.3279,58.641 Z"
      fill="#000000"
    ></path>
    <path
      d="M25.9588,59.7857 C13.092,59.7857 3.9869,55.4493 0,51.8007 L8.53943,39.2537 C13.8883,43.8158 20.4946,46.0995
          25.9588,46.0995 C30.6266,46.0995 32.675,44.7293 32.675,42.2199 C32.675,40.2828 31.0824,38.7969 26.0741,37.6578
          L15.9421,35.264 C6.03527,32.9747 1.36741,26.9268 1.36741,18.6006 C1.36741,7.19269 10.4725,0.462402 24.0202,0.462402
          C34.4927,0.462402 42.9168,3.6542 47.0191,7.30825 L38.4796,19.8553 C34.7234,16.4324 29.2592,13.923 24.0202,13.923
          C19.6929,13.923 17.7598,15.4088 17.7598,17.6871 C17.7598,19.9654 19.8082,21.2256 23.6798,22.1336 L33.8118,24.4174
          C44.1689,26.7011 49.1827,32.2868 49.1827,40.5029 C49.1827,52.252 40.4127,59.7857 25.9588,59.7857 Z"
      fill="#F4333D"
    ></path>
  </svg>
);
