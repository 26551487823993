import React from 'react';

import ContentBlock from '../components/ContentBlock';
import HocAuthenticate from '../components/HocAuthenticate';
import Layout from '../components/Layout';
import MyInfoDetails from '../components/MyInfo/MyInfoDetails';
import PageContent from '../components/PageContent';
import PageContextProvider from '../components/PageContextProvider';
import PageTitle from '../components/PageTitle';
import Profile from '../components/Profile';
import ProfileVerificationBanner from '../components/Profile/ProfileVerificationBanner';
import ProfileTasks from '../components/ProfileTasks';
import AccountContainer from '../containers/Account';
import { ampVertical, eventNames } from '../services/event-tracking/events';
import { track } from '../services/event-tracking/services';

const TaskAndProfile = () => (
  <>
    <ProfileVerificationBanner />
    <ProfileTasks />
    <ContentBlock>
      <PageTitle>Profile</PageTitle>
      <PageContent>
        <Profile />
      </PageContent>
    </ContentBlock>
    <MyInfoDetails />
  </>
);

const PageIndex = (props) => {
  const { fetchUserProfile, userData, authLoading } = props;

  React.useEffect(() => {
    const eventName = eventNames.SAM_PROFILE_PAGE_VIEWED;
    const trackingProps = {
      user: userData,
      dataLayer: window.dataLayer,
      backTo: window.backTo,
      vertical: ampVertical.ACCOUNT,
    };
    track(eventName, trackingProps);
  }, [userData]);

  return (
    <>
      <AccountContainer user={userData} loading={authLoading} refreshUserData={fetchUserProfile}>
        <Layout>
          <PageContextProvider>
            <TaskAndProfile />
          </PageContextProvider>
        </Layout>
      </AccountContainer>
    </>
  );
};

export default HocAuthenticate(PageIndex);
