let instance = null;

// in anonymous mode of browsers, we are unable to save data in localStorage
// so we need to replicate localStorage and keep all changes in local data
class LocalStorageService {
  constructor() {
    this.reset();

    if (!instance) {
      instance = this;
    }

    return instance;
  }

  reset() {
    this.supported = this.isSupported();
  }

  currentLocalStorage = {};

  isSupported = () => {
    try {
      const key = '__storage_test__';
      const value = '__storage_test_value__';
      if (typeof window === 'undefined' || !window.localStorage) {
        return false;
      }
      window.localStorage.setItem(key, value);
      window.localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  };

  supported = this.isSupported();

  getItem = (key) => {
    if (this.supported) {
      return window.localStorage.getItem(key);
    }
    return this.currentLocalStorage[key];
  };

  setItem = (key, value) => {
    if (this.supported) {
      window.localStorage.setItem(key, value);
    }
    this.currentLocalStorage[key] = value;
  };

  removeItem = (key) => {
    if (this.supported) {
      window.localStorage.removeItem(key);
    }
    delete this.currentLocalStorage[key];
  };
}

export default new LocalStorageService();
