import React from 'react';
import Box from '@ntuctech/devex-tangram/Box';
import Grid from '@ntuctech/devex-tangram/Grid';
import SvgArrowLeft from '@ntuctech/devex-tangram/Icon/SvgArrowLeft';
import NdsThemeProviderPurple from '@ntuctech/devex-tangram/Theme/NdsThemeProviderPurple';
import Typography from '@ntuctech/devex-tangram/Typography';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled, { createGlobalStyle } from 'styled-components';

import { isHpbFlow, isNudgeFlow } from '../../services/flow-from-url';
import getCrossDomainReferrer from '../../services/get-referrer';
import { getDisplayPath, getReferrerEntity } from '../../services/get-referrer/get-referrer';
import { useUser } from '../../services/profile';
/* feature flags */
import { useFeatures } from '../DynamicFeatureProvider';
import LeftNavigation from '../LeftNavigation';
import PageHeader from '../PageHeader';
import Loader from '../Theme/Loader';

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    background-color: #f4f4f4;
  }
  /* lato-regular - latin-ext_latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://media.nedigital.sg/nedigital/files/daec682d-3287-405a-aa79-96223c4b497f/lato-v16-latin-ext_latin-regular.eot');
  /* IE9 Compat Modes */
    src: local('Lato Regular'), local('Lato-Regular'), url('https://media.nedigital.sg/nedigital/files/daec682d-3287-405a-aa79-96223c4b497f/lato-v16-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */
    url('https://media.nedigital.sg/nedigital/files/9cf5fe64-979a-46af-a184-af1cd2c77395/lato-v16-latin-ext_latin-regular.woff2') format('woff2'),
  /* Super Modern Browsers */
    url('https://media.nedigital.sg/nedigital/files/9fc0d407-b429-4c2d-9790-860be8ea8fa9/lato-v16-latin-ext_latin-regular.woff') format('woff'),
  /* Modern Browsers */
    url('https://media.nedigital.sg/nedigital/files/3a1f5374-6aba-42e9-936c-e11e05682f7d/lato-v16-latin-ext_latin-regular.ttf') format('truetype'),
  /* Safari, Android, iOS */
    url('https://media.nedigital.sg/nedigital/files/f38150ff-ee7f-44f8-9837-9f811f0ff1d6/lato-v16-latin-ext_latin-regular.svg#Lato') format('svg');
  /* Legacy iOS */
  }
  /* lato-700 - latin-ext_latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('https://media.nedigital.sg/nedigital/files/f8cbf2f4-4d0c-4989-af28-4c0c07c03925/lato-v16-latin-ext_latin-700.eot');
  /* IE9 Compat Modes */
    src: local('Lato Bold'), local('Lato-Bold'), url('https://media.nedigital.sg/nedigital/files/f8cbf2f4-4d0c-4989-af28-4c0c07c03925/lato-v16-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */
    url('https://media.nedigital.sg/nedigital/files/062804f9-65e6-46df-b4a3-69d515ee444c/lato-v16-latin-ext_latin-700.woff2') format('woff2'),
  /* Super Modern Browsers */
    url('https://media.nedigital.sg/nedigital/files/67fb7ecc-87c0-4613-aa21-892f5d5678cb/lato-v16-latin-ext_latin-700.woff') format('woff'),
  /* Modern Browsers */
    url('https://media.nedigital.sg/nedigital/files/0270b03c-3d43-4e17-9402-8d3a1bfab940/lato-v16-latin-ext_latin-700.ttf') format('truetype'),
  /* Safari, Android, iOS */
    url('https://media.nedigital.sg/nedigital/files/10ccbb7c-f796-4689-a0f6-9bec2c42fc8d/lato-v16-latin-ext_latin-700.svg#Lato') format('svg');
  /* Legacy iOS */
  }
  /* lato-900 - latin-ext_latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('https://media.nedigital.sg/nedigital/files/686e1a69-f900-404f-8011-c7cc8768717e/lato-v16-latin-ext_latin-900.eot');
  /* IE9 Compat Modes */
    src: local('Lato Black'), local('Lato-Black'), url('https://media.nedigital.sg/nedigital/files/686e1a69-f900-404f-8011-c7cc8768717e/lato-v16-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */
    url('https://media.nedigital.sg/nedigital/files/72173d62-62f2-4965-85d2-98262c76f0af/lato-v16-latin-ext_latin-900.woff2') format('woff2'),
  /* Super Modern Browsers */
    url('https://media.nedigital.sg/nedigital/files/ec8552d4-383a-4dde-8e47-07adcce35f9a/lato-v16-latin-ext_latin-900.woff') format('woff'),
  /* Modern Browsers */
    url('https://media.nedigital.sg/nedigital/files/c6acdf36-3c71-4a1f-8587-be2067ed1f30/lato-v16-latin-ext_latin-900.ttf') format('truetype'),
  /* Safari, Android, iOS */
    url('https://media.nedigital.sg/nedigital/files/5c3ed274-1d25-43af-a4c4-83f4bf977a08/lato-v16-latin-ext_latin-900.svg#Lato') format('svg');
  /* Legacy iOS */
  }
`;

const ResponsiveWrapper = styled.div`
  margin-top: 4rem;

  @media (max-width: 767px) {
    & .ndsHidden-xs-down {
      display: none;
    }
  }
  @media (min-width: 768px) {
    & .ndsHidden-s-up {
      display: none;
    }
  }
`;

const NavLinkWrapper = styled(Box)`
  width: 100%;
  max-width: 45.75rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 1.5rem;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    top: 1rem;
  }

  @media (min-width: 768px) {
    & svg {
      display: none;
    }
  }
`;

const BreadCrumbGroupWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CustomLink = styled.a`
  font-family: lato;
  font-size: 0.875rem;
  font-weight: bold;
  color: ${(p) => (p.isLast ? '#73308A' : '#333333')};
  text-decoration: none;
`;

const StyledGrid = styled(Grid)`
  position: relative;
  padding-top: 2rem;
`;

const StyledGridMobile = styled(Grid)`
  @media (max-width: 767px) {
    padding: 0 1rem;
  }
`;

const BreadCrumbSlashTypography = styled(Typography)`
  margin: 0 0.75rem;
`;

const BackToLink = ({ backLink }) => {
  const { user } = useUser();
  const router = useRouter();
  const emailVerified = user?.email_last_verified;
  const phoneVerified = user?.phone_number_last_verified;
  const getExterPartyParam = router.query?.party;
  const [breadCrumbDisplay, setBreadCrumbDisplay] = React.useState([]);
  const [breadCrumbLink, setBreadCrumbLink] = React.useState(null);

  React.useEffect(() => {
    let { displayPath, routerPath } = getDisplayPath(
      emailVerified,
      phoneVerified,
      getExterPartyParam,
    );
    if (backLink) {
      const getEntityDesc = getReferrerEntity(backLink);
      displayPath = [getEntityDesc, ...displayPath];
      routerPath = [backLink, ...routerPath];
    }
    setBreadCrumbDisplay(displayPath);
    setBreadCrumbLink(routerPath);
  }, [backLink, emailVerified, phoneVerified]);

  return (
    <NavLinkWrapper>
      <SvgArrowLeft />
      {breadCrumbDisplay.map((crumb, index) => {
        const isLast = breadCrumbDisplay.length === index + 1;
        const isShownInMobile = index + 1 <= breadCrumbDisplay.length - 2;

        let linkHref = '';
        // has referrer, link == referrer (first item in breadCrumbLink array)
        if (index === 0 && backLink) {
          linkHref = breadCrumbLink[index];
        } else {
          // slice breadCrumbLink array one item back if backlink exist
          const getCrumbPath = backLink
            ? breadCrumbLink.slice(1, index + 1)
            : breadCrumbLink.slice(0, index + 1);

          const getLastPath = breadCrumbLink[breadCrumbLink.length - 1];
          const isVerifyPath =
            index === breadCrumbLink.length - 1 &&
            (getLastPath === 'verify-mobile' || getLastPath === 'verify-email');
          const path = isVerifyPath ? getCrumbPath.join('/') : `/${getCrumbPath.join('/')}`;
          linkHref = path;
        }

        return (
          <BreadCrumbGroupWrapper key={crumb} className={isShownInMobile && 'ndsHidden-xs-down'}>
            <Link
              href={linkHref}
              isLast={isLast}
              passHref={true}
              data-testid="GoBackLink"
              legacyBehavior
            >
              <CustomLink isLast={isLast} data-testid="GoBackLink">
                {crumb}
              </CustomLink>
            </Link>
            {!isLast && <BreadCrumbSlashTypography variant="subhead1">/</BreadCrumbSlashTypography>}
          </BreadCrumbGroupWrapper>
        );
      })}
    </NavLinkWrapper>
  );
};

const Layout = ({ children }) => {
  const [backLink, setBackLink] = React.useState('');
  const { isReady } = useFeatures();
  const enableNudgeFlowUi = isNudgeFlow();
  const isHpbIntegrationPage = isHpbFlow();
  const [shouldShowLeftNavitation, shouldShowBackLink] = Array(2).fill(
    !enableNudgeFlowUi && !isHpbIntegrationPage,
  );

  React.useEffect(() => {
    setBackLink(getCrossDomainReferrer(window.backTo));
  }, []);

  if (!isReady) {
    return (
      <NdsThemeProviderPurple>
        <Loader />
      </NdsThemeProviderPurple>
    );
  }

  return (
    <NdsThemeProviderPurple>
      <>
        <GlobalStyle />
        <ResponsiveWrapper>
          <PageHeader />
          <StyledGridMobile container>
            {shouldShowLeftNavitation && <LeftNavigation />}
            <Grid item s={1} m={2} />
            <StyledGrid item s={10} m={8}>
              {shouldShowBackLink && <BackToLink backLink={backLink} />}
              {children}
            </StyledGrid>
            <Grid item s={1} m={2}></Grid>
          </StyledGridMobile>
        </ResponsiveWrapper>
      </>
    </NdsThemeProviderPurple>
  );
};

export default Layout;
