import { datadogRum } from '@datadog/browser-rum';

import { ERROR_UNAUTHORIZED } from './constants';

const checkErrorUnauthorized = (option) => {
  return option.headers?.Authorization && option.headers.Authorization.split(' ')[1] === 'null';
};

// use this for fetch to external APIs (server-side and client-side)
export const fetcherExternal = async (url, option) => {
  try {
    const res = await fetch(url, option);
    const result = await res.text();

    if (checkErrorUnauthorized(option)) {
      throw new Error(ERROR_UNAUTHORIZED);
    }

    if (!res.ok) {
      let error = null;
      try {
        // error response is JSON format
        error = JSON.parse(result);
      } catch (e) {
        // error response is not JSON format
        error = result;
      }

      let errorMessage = '';
      let errorInfo = {};

      if (error !== null && typeof error === 'object') {
        errorInfo = error;
        errorMessage = error.data?.error_description;
      } else {
        errorMessage = error;
      }

      errorInfo = {
        ...errorInfo,
        ...(errorMessage && { message: errorMessage }),
        code: res.status,
      };

      throw new Error(`Backend API Error ${JSON.stringify(errorInfo)}`);
    }

    return result === '' ? {} : JSON.parse(result);
  } catch (e) {
    if (typeof window !== 'undefined') {
      datadogRum.addError(e);
    }
    throw e;
  }
};

// use this for fetch to "/api/xxx" API routes on client-side
export const fetcherApiRoute = async (url, option) => {
  try {
    const res = await fetch(
      url,
      option && {
        ...option,
        headers: { 'Content-Type': 'application/json', ...option.headers },
      },
    );

    // because we intercepted all errors from all API routes
    // the res here is always in JSON format {code: ..., error: ...}
    const result = await res.json();

    if (!res.ok) {
      // for frontend error handling (UI)
      throw new Error(result.error);
    }

    return result;
  } catch (e) {
    datadogRum.addError(new Error(`Fetch error ${e.message}`));
    return Promise.reject(e);
  }
};
