import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { object as YupObject } from 'yup';

import { fetcherApiRoute } from '../../services/api/fetcher';
import InputValidator from '../../services/input-validator';
import Logger from '../../services/logger';

import EditNameFormContent from './EditNameFormContent';

const EditNameForm = ({ initialValues, handleCallback, ...others }) => {
  const onSubmit = (values, actions) => {
    fetcherApiRoute('/api/profile', {
      method: 'PATCH',
      body: JSON.stringify(values),
    })
      .then((result) => {
        Logger.debug('[API][patchName] edit name result:', result);
        actions.setSubmitting(false);
        // only reset form when submission is successful
        actions.resetForm();
        handleCallback(false, result, 'name');
      })
      .catch((error) => {
        Logger.debug('[API][patchName] edit name error:', error);
        actions.setSubmitting(false);
        handleCallback(error, {});
        // do not reset form when there is error
      })
      .finally(() => {
        // do not call setState here
        // because form might be closed
        // from handleCallback() above
        // and there will be error on
        // "set state on unmounted component"
      });
  };

  const NameSchema = YupObject().shape({
    name: InputValidator.nameValidation({
      emptyNameMsg: 'Your name must not be empty.',
      invalidNameMsg:
        'Your name must only contain letters, numbers, dots(.), hyphens(-) and slashes(/).',
      maxLengthMessage: 'Your name must be within 40 characters.',
    }),
  });

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={NameSchema}>
      {(formikProps) => {
        return <EditNameFormContent {...formikProps} {...others} />;
      }}
    </Formik>
  );
};

EditNameForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  handleCallback: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  setIsEditing: PropTypes.func.isRequired,
};

export default EditNameForm;
