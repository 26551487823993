// endpoints
export const SDK_API_BASE_URL = `https://${process.env.IDENTITY_API_HOST}/sdk`;
export const BRIDGE_API_BASE_URL = `https://${process.env.IDENTITY_API_HOST}/bridge/user`;
export const PWL_START_URL = `${process.env.AUTH0_ISSUER_BASE_URL}/passwordless/start`;
export const SDK_API_EMAIL_AVAILABILITY_URL = `${SDK_API_BASE_URL}/pwl/availability/email`;
export const BRIDGE_API_AUD = 'https://bridge.identity.nedigital.sg';
export const M2M_ENDPOINT = `https://${process.env.AUTH0_M2M_AUTH_DOMAIN}/oauth/token`;
export const PLUS_API_BASE_URL = `${process.env.PLUS_API_HOST}/me`;
export const MEMBERSHIP_RESTORATION_BASE_URL = `https://${process.env.IDENTITY_API_HOST}/sdk/link-point-restoration`;
export const HPB_LOGIN_API = `https://${process.env.IDENTITY_API_HOST}/sdk/integration/hpb/login`;
export const ADDRESS_SEARCH_API = 'https://www.onemap.gov.sg/api/common/elastic/search';

// errors
export const ERROR_UNAUTHORIZED = 'Unauthorized.';
export const ERROR_UNEXPECTED = 'An unexpected error has occured.';
export const ERROR_INVALID_REQUEST = 'Invalid request.';
export const ERROR_WRONG_OTP = 'The OTP you’ve entered is incorrect. Please try again.';
export const ERROR_INVALID_GRANT = 'Wrong email or verification code.';
export const ERROR_INVALID_JWT = 'Invalid JWT token.';
export const ERROR_INVALID_TOKEN = 'Invalid token.';
export const ERROR_SOMETHING_WRONG = 'Something went wrong. Please try again.';
export const ERROR_MYINFO_DENIED = 'Myinfo verification was unsuccessful.';

// others
export const PWL_CONNECTION_EMAIL = 'email';
export const PWL_CONNECTION_SMS = 'ned-sms';
export const JWT_VALID_UID =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiIxMjM0NSIsImFwcGxpY2F0aW9uIjoibW9ja19hcHBsaWNhdGlvbiJ9.Kqdj_HE8R-Cl8AAaGeB-pFFBDrpDYoQOvn4Ibsaked0';
export const JWT_VALID_EMAIL =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImZsb3JhLndva2VAZ21haWwuY29tIiwiYXBwbGljYXRpb24iOiJmYWlycHJpY2UifQ.IQsjRlTJdnevywdjS8le4Qba_xmVptXm_kmi891oPEw';
export const JWT_INVALID_PAYLOAD =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzb21ldGhpbmciOiJzb21ldGhpbmcgaW52YWxpZCJ9.8JfHmRonY8deBLljcY6OiJYXiEgRjL9X8CLmGC-6-Tk';
export const JWT_INVALID = 'aaa.bbb.ccc';

export const ERRORS_IGNORED_DATADOG = ['Fetch error Unauthorized.', 'Script error.'];
