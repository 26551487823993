import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { UNSAVE_ALERT } from './index';

const PageContext = createContext({});

export const usePageContext = () => useContext(PageContext);

const PageContextProvider = (props) => {
  const { children, initialView } = props;

  const [pageContext, setPageContext] = useState(initialView || '');
  const [isEditing, setIsEditing] = useState(false);

  const checkUnsavedBeforeUpdate = React.useCallback(
    (updateAction) => {
      if (isEditing) {
        if (window.confirm(UNSAVE_ALERT)) {
          updateAction();
        } else {
          return;
        }
      }
      updateAction();
    },
    [isEditing],
  );

  return (
    <PageContext.Provider
      value={{
        pageContext,
        setPageContext,
        isEditing,
        setIsEditing,
        checkUnsavedBeforeUpdate,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

PageContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageContextProvider;
