import React from 'react';
import Box from '@ntuctech/devex-tangram/Box';
import ButtonIcon from '@ntuctech/devex-tangram/Button/ButtonIcon';
import SvgArrowDown from '@ntuctech/devex-tangram/Icon/SvgArrowDown';
import SvgArrowUp from '@ntuctech/devex-tangram/Icon/SvgArrowUp';
import Link from '@ntuctech/devex-tangram/Link';
import Typography from '@ntuctech/devex-tangram/Typography';
import clsx from 'clsx';
import styled from 'styled-components';

const AccordionSC = styled.div`
  .accordion__content--open {
    height: auto;
    opacity: 1;
    transition: all 0.3s linear;
  }

  .accordion__content--close {
    height: 0px;
    opacity: 0;
    transition: all 0.3s linear;
    overflow: hidden;
  }

  .accordion__teaser {
    animation: accordion__teaser--Open__kf cubic-bezier(0.42, 0, 0.58, 1) 0.5s;
  }

  @keyframes accordion__teaser--Open__kf {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .accordion__title {
    position: relative;
    display: flex;
    align-items: center;
  }

  .accordion__toggle-btn {
    margin-left: auto !important;
    cursor: pointer;
  }
`;

const LinkSc = styled(Link)`
  font-weight: 400;
  font-size: 0.75rem;
`;

export function Accordion({
  title,
  teaser, // default teaser message for when component is collapsed
  teaserExpand, // default teaser message for when component is expanded
  loading = true,
  initialOpen = false,
  toggleType = 'arrow',
  highlightComp, // a child component that will never hide
  children,
  testId,
  appName,
  ...rest
}) {
  const [open, setOpen] = React.useState(initialOpen);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleAccordionToggle = React.useCallback(() => {
    setOpen(!open);
  });

  const contentClassNames = clsx(
    { 'accordion__content--open': open },
    { 'accordion__content--close': !open },
  );

  const Arrow = open ? SvgArrowUp : SvgArrowDown;
  const TextToggle = (props) => <Link {...props}>{open ? 'Show less' : 'Show all'}</Link>;

  const showTeaser = !loading && teaser;
  const testIdSuffix = React.useMemo(() => (testId ? `--${testId}` : ''), [testId]);

  const teaserOpen = React.useMemo(() => teaserExpand || teaser, [teaserExpand, teaser]);

  const FpgGroupOrgs = () => {
    return (
      appName === 'fairprice' &&
      open && (
        <Typography variant="body4">
          FairPrice Group refers to NTUC FairPrice Co-operative Limited and its affiliated
          organisations as listed{' '}
          <LinkSc href="https://www.fairpricegroup.com.sg/our-businesses/" target="_blank">
            here
          </LinkSc>
          .
        </Typography>
      )
    );
  };

  return (
    <AccordionSC {...rest}>
      <div className="accordion__title">
        <Typography variant="h4" data-testid={`accordion__title${testIdSuffix}`}>
          {title}
        </Typography>
        {toggleType === 'text' && (
          <TextToggle
            onClick={handleAccordionToggle}
            className="accordion__toggle-btn"
            data-testid={`accordion__toggle${testIdSuffix}`}
          />
        )}
        {toggleType === 'arrow' && (
          <ButtonIcon
            className="accordion__toggle-btn"
            color="grey"
            onClick={handleAccordionToggle}
            compact
            data-testid={`accordion__toggle${testIdSuffix}`}
          >
            <Arrow />
          </ButtonIcon>
        )}
      </div>
      {showTeaser && (
        <div className="accordion__teaser" data-testid={`accordion__teaser${testIdSuffix}`}>
          {open ? teaserOpen : teaser}
        </div>
      )}
      <FpgGroupOrgs />
      {highlightComp}
      <Box
        {...((!highlightComp || open) && { mt: 3 })}
        className={contentClassNames}
        data-testid={`accordion__content${testIdSuffix}`}
      >
        {children}
      </Box>
    </AccordionSC>
  );
}
