import React from 'react';
import Box from '@ntuctech/devex-tangram/Box';
import Typography from '@ntuctech/devex-tangram/Typography';
import Head from 'next/head';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { isNudgeFlow } from '../../services/flow-from-url';
import getCrossDomainReferrer from '../../services/get-referrer';
import BackButton from '../Theme/BackButton';

const StyledTypography = styled(Typography)`
  display: flex;
  align-items: flex-start;

  & > * {
    margin-right: 1rem;
  }
`;

const PageTitleSC = styled(Box)`
  &::after {
    content: '';
    height: 0.0625rem;
    background-color: #eaeaea;
    width: 100%;
    display: block;
    margin-top: 1rem;
  }
`;

const PageTitle = (props) => {
  const { children, backAction: backActionProp, testId, boxMb } = props;

  const router = useRouter();
  const enableNudgeFlowUi = isNudgeFlow();

  const backAction = React.useCallback(() => {
    const crossDomainReferrer = getCrossDomainReferrer(window.backTo, enableNudgeFlowUi);
    if (enableNudgeFlowUi && crossDomainReferrer) {
      router.push(crossDomainReferrer);
    } else {
      backActionProp && backActionProp();
    }
  }, [backActionProp, enableNudgeFlowUi, router]);

  const backButton = React.useMemo(
    () =>
      backActionProp ? <BackButton onClick={backAction} data-testid={`${testId}Back`} /> : null,
    [backActionProp, backAction, testId],
  );

  return (
    <>
      <Head>
        <title>{children} | Linkpass</title>
      </Head>

      <div className="ndsHidden-xs-down">
        <PageTitleSC mb={boxMb ? boxMb : 3}>
          <StyledTypography variant="h3">
            {backButton}
            {children}
          </StyledTypography>
        </PageTitleSC>
      </div>
      <div className="ndsHidden-s-up">
        <PageTitleSC mb={2} className="pageTitle">
          <StyledTypography variant="h4">
            {backButton}
            {children}
          </StyledTypography>
        </PageTitleSC>
      </div>
    </>
  );
};

export default PageTitle;
