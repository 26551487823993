/* Auth0 uses validator.js isEmail to valide email
 * so front-end validation must follow
 */
import _isEmail from 'validator/lib/isEmail';
import { date as YupDate, ref as YupRef, string as YupString } from 'yup';

/* extra guard as validator.js isEmail method
 * does not allow undefined values */
const isEmail = (value) => value && _isEmail(value);

const nameValidation = ({ emptyNameMsg, invalidNameMsg, maxLengthMessage }) =>
  YupString()
    .required(emptyNameMsg)
    .max(40, maxLengthMessage)
    .matches(
      /* eslint-disable */
      new RegExp(/^[a-zA-Z\xc0-\xff]+(?:(?:[a-zA-Z0-9\xc0-\xff ',․\.\-\/])?)+$/i),
      /* eslint-disable */
      invalidNameMsg,
    );

const emailValidation = ({ emptyEmailMessage, invalidEmailMessage, maxLengthMessage }) =>
  YupString()
    .trim()
    .required(emptyEmailMessage)
    .max(64, maxLengthMessage)
    .test('email-validation-auth0', invalidEmailMessage, (value) => isEmail(value));

const phoneValidation = ({ emptyPhoneMsg, invalidPhoneMsg }) =>
  YupString()
    .required(emptyPhoneMsg)
    .matches(
      /* eslint-disable */
      new RegExp(/^[89]\d{7}$/i),
      /* eslint-disable */
      invalidPhoneMsg,
    );

const currentPasswordValidation = ({ emptyPasswordMessage }) => {
  return YupString().trim().required(emptyPasswordMessage).strict(true).label('Current password');
};

const newPasswordValidation = ({ minLengthMessage, minOneNumberMessage, minOneLetterMessage }) => {
  return YupString()
    .trim()
    .min(8, minLengthMessage)
    .matches(new RegExp(/\d+/i), minOneNumberMessage)
    .matches(new RegExp(/[A-z]+/i), minOneLetterMessage)
    .strict(true)
    .label('New password');
};

const confirmPasswordValidation = ({ emptyPasswordMessage, matchPasswordMessage }) => {
  return YupString()
    .trim()
    .required(emptyPasswordMessage)
    .oneOf([YupRef('newPassword'), null], matchPasswordMessage)
    .strict(true)
    .label('Confirm password');
};

const parseDateString = (value, originalValue) => {
  // do not parse if originalValue is already a Date object
  if (originalValue && typeof originalValue.getMonth === 'function') {
    return originalValue;
  }

  // raw input: dd/mm/yyyy
  const dateParts = (originalValue + '').split('/');
  // to form a Date object the input has to be in mm/dd/yyyy format
  // however new Date() also allows "dd/mm/yy", but our backend does not
  // so we pad invalid characters in front and always get the last 4 characters
  const padYear = `xx${dateParts[2]}`;
  const year = padYear.slice(-4);
  return new Date(`${dateParts[1]}/${dateParts[0]}/${year}`);
};

const today = new Date();
today.setDate(today.getDate() - 1); // yesterday

const dateOfBirthValidation = ({ emptyDoBMessage, futureDateMessage, typeErrorMessage }) => {
  return YupDate()
    .required(emptyDoBMessage)
    .transform(parseDateString)
    .max(today, futureDateMessage)
    .label('Date of birth')
    .typeError(typeErrorMessage)
    .nullable();
};

const requiredErrorMessage = (fieldName) => `Please enter ${fieldName}.`;

const requiredValidation = (fieldName, customErrorMessage = '') =>
  YupString()
    .trim()
    .required(customErrorMessage || requiredErrorMessage(fieldName));

const floorUnitValidation = ({ fieldName }) =>
  YupString().when('hasNoFloorUnit', (hasNoFloorUnit, schema) => {
    return !hasNoFloorUnit ? schema.trim().required(requiredErrorMessage(fieldName)) : schema;
  });

export const addressValidation = {
  requiredValidation,
  floorUnitValidation,
};

const plusCardNumberValidation = ({ lengthMessage, invalidMessage, fieldName }) =>
  YupString().when('lpTransferRadioChoice', (value, schema) => {
    return value === 'card'
      ? schema
          .trim()
          .required(requiredErrorMessage(fieldName))
          .min(19, lengthMessage)
          .max(19, lengthMessage)
          .matches(
            /* eslint-disable */
            new RegExp(/^(8108|8103|8105|8301|8201|8109|4524|4349) \d{4} \d{4} \d{4}$/i),
            /* eslint-disable */
            invalidMessage,
          )
      : schema;
  });

const linkCardNumberValidation = ({ lengthMessage, invalidMessage, fieldName }) => {
  return YupString()
    .trim()
    .required(requiredErrorMessage(fieldName))
    .min(19, lengthMessage)
    .max(19, lengthMessage)
    .matches(
      /* eslint-disable */
      new RegExp(/^(8108|8103|8105|8301|8201|8109|4524|4349) \d{4} \d{4} \d{4}$/i),
      /* eslint-disable */
      invalidMessage,
    );
};

export const plusMemberValidation = {
  plusCardNumberValidation,
  emailValidation: ({ emptyEmailMessage, invalidEmailMessage, maxLengthMessage }) =>
    YupString().when('lpTransferRadioChoice', (value, schema) => {
      return value === 'email'
        ? emailValidation({
            emptyEmailMessage,
            invalidEmailMessage,
            maxLengthMessage,
          })
        : schema;
    }),
  phoneValidation: ({ emptyPhoneMsg, invalidPhoneMsg }) =>
    YupString().when('lpTransferRadioChoice', (value, schema) => {
      return value === 'mobile'
        ? phoneValidation({
            emptyPhoneMsg,
            invalidPhoneMsg,
          })
        : schema;
    }),
};

export const otpValidation = (otp) => otp.length < 6 || !/^\d+$/.test(otp);

export default {
  nameValidation,
  emailValidation,
  phoneValidation,
  currentPasswordValidation,
  newPasswordValidation,
  confirmPasswordValidation,
  dateOfBirthValidation,
  linkCardNumberValidation,
};
