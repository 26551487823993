import React from 'react';
import Typography from '@ntuctech/devex-tangram/Typography';
import styled from 'styled-components';

const EndorsementSc = styled.div`
  display: flex;
  align-items: center;
`;

const LogoLinkSvg = styled.div`
  width: 3.25rem;
  height: 0.845rem;
  background-image: url('https://media.nedigital.sg/linkpass/files/3364f6ff-72e1-4c64-9e2c-8b10746c04cd/Link_Logo_RGB.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  margin-left: 0.25rem;
`;

const Endorsement = ({ className }) => {
  return (
    <EndorsementSc className={className}>
      <Typography variant="body4" data-testid="link-endorsement">
        powered by
      </Typography>
      <LogoLinkSvg />
    </EndorsementSc>
  );
};

export default Endorsement;
