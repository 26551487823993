/* eslint-disable react-hooks/exhaustive-deps */

import React, { Children } from 'react';
import Typography from '@ntuctech/devex-tangram/Typography';
import styled from 'styled-components';

import { FLAGS } from '../../services/feature-flag';
import LocalStorageService from '../../services/local-storage';
import { useUser } from '../../services/profile';
import { getProfileTasks } from '../../services/profile/profile-tasks';
import ContentBlock from '../ContentBlock';
import { useFeatures } from '../DynamicFeatureProvider';
import HocProfileManager from '../HocProfileManager';
import { Accordion, SvgSingPass, SvgStar } from '../Theme';

import TaskRow from './TaskRow';

const StyledAccordion = styled(Accordion)`
  & h5 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: normal;
  }
  & a {
    font-weight: normal;
  }
  & .accordion__title {
    padding-bottom: 0.75rem;
    border-bottom: 0.0625rem solid #eaeaea;
  }
  & .accordion__content--open > div:last-child {
    padding-bottom: 0;
  }
`;

const TaskDoneBannerWrapper = styled(ContentBlock)`
  background-color: rgba(198, 236, 215, 0.3);
  border: none;
  display: flex;
  align-items: center;
  padding: 1rem;

  & > svg {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }
`;

const ProfileTasksWrapper = ({ children }) => {
  const childCompsArr = Children.toArray(children);
  const taskCount = childCompsArr.length;
  const lessPrioritizedTasks = childCompsArr.slice(1);

  return (
    <ContentBlock data-testid="ProfileTasks">
      <StyledAccordion
        title={`${taskCount} incomplete task${taskCount > 1 ? 's' : ''}`}
        loading={false}
        toggleType={taskCount === 1 ? 'hidden' : 'text'}
        highlightComp={childCompsArr[0]}
        testId="profile-tasks"
      >
        {lessPrioritizedTasks}
      </StyledAccordion>
    </ContentBlock>
  );
};

const TaskDoneBanner = () => (
  <TaskDoneBannerWrapper data-testid="ProfileTaskDoneBanner">
    <SvgStar />
    <Typography variant="h6">Profile completed!</Typography>
  </TaskDoneBannerWrapper>
);

const mobileVerificationTaskContent = (
  <div>
    <Typography variant="h6">Verify your mobile number</Typography>
    <div className="ndsHidden-xs-down">
      <Typography variant="body2">This lets you log in with your mobile number.</Typography>
    </div>
    <div className="ndsHidden-s-up">
      <Typography variant="body4">This lets you log in with your mobile number.</Typography>
    </div>
  </div>
);

const myinfoVerificationTaskContent = (
  <div>
    <Typography variant="h6">Verify your age</Typography>
    <div className="ndsHidden-xs-down task__desc--with-icon">
      <Typography variant="body2">Via </Typography>
      <SvgSingPass />
    </div>
    <div className="ndsHidden-s-up task__desc--with-icon">
      <Typography variant="body4">Via </Typography>
      <SvgSingPass />
    </div>
  </div>
);

const emailVerificationTaskContent = (
  <div>
    <Typography variant="h6">Verify your email</Typography>
    <div className="ndsHidden-xs-down">
      <Typography variant="body2">We want to make sure your account stays secure.</Typography>
    </div>
    <div className="ndsHidden-s-up">
      <Typography variant="body4">We want to make sure your account stays secure.</Typography>
    </div>
  </div>
);

const shouldSetHasClickedMyinfoBanner = (localStorageService) => {
  return !localStorageService.getItem('hasClickedMyinfoBanner');
};

const ProfileTasks = (props) => {
  const { userIsReady, user: localUser, profileCompletionStatus } = useUser();
  const profileTasks = getProfileTasks(profileCompletionStatus); // array of incomplete task names
  const { isFlagEnabled } = useFeatures();
  const isNewProfilePageEnabled = isFlagEnabled(FLAGS.PROFILE_VERIFICATION_REVAMP);

  const { handleOpenForm } = props;

  const storageKeyForProfileTaskDoneMsgShown = React.useMemo(
    () => `profileTaskDoneMsgShown-${localUser && localUser.uid}`,
    [localUser],
  );

  const isProfileTaskDoneMsgShown = React.useMemo(
    () => LocalStorageService.getItem(storageKeyForProfileTaskDoneMsgShown),
    [LocalStorageService, storageKeyForProfileTaskDoneMsgShown],
  );

  const setProfileTaskDoneMsgShownState = React.useCallback(() => {
    LocalStorageService.setItem(storageKeyForProfileTaskDoneMsgShown, 'true');
  }, []);

  const shouldShowProfileTaskDoneMsg = userIsReady && !isProfileTaskDoneMsgShown;

  const topPriorityTaskName = profileTasks[0];

  // must match profileStatusChecklist in
  // '../../services/profile/profile-tasks'
  const taskRenderDetails = {
    mobile_verification: {
      label: 'Verify',
      action: handleOpenForm('verify-mobile'),
      desc: mobileVerificationTaskContent,
    },
    myinfo_verification: {
      label: 'Verify',
      action: handleOpenForm('myinfo'),
      desc: myinfoVerificationTaskContent,
    },
    email_verification: {
      label: 'Verify',
      action: handleOpenForm('verify-email'),
      desc: emailVerificationTaskContent,
    },
  };

  const renderTask = React.useCallback(
    (taskName) => {
      return (
        <TaskRow
          key={taskName}
          taskName={taskName}
          ctaText={taskRenderDetails[taskName].label}
          ctaAction={taskRenderDetails[taskName].action}
          isTopPriority={topPriorityTaskName === taskName}
        >
          {taskRenderDetails[taskName].desc}
        </TaskRow>
      );
    },
    [topPriorityTaskName, taskRenderDetails],
  );

  const renderTasks = React.useCallback(() => {
    const taskComps = [];
    for (const name of profileTasks) {
      taskComps.push(renderTask(name));
    }
    return taskComps;
  }, [profileTasks, renderTask]);

  React.useEffect(() => {
    if (profileTasks.length === 0) {
      shouldShowProfileTaskDoneMsg && setProfileTaskDoneMsgShownState();
    } else {
      // if remaining task count is greater than 0
      // clear the local storage status
      LocalStorageService.removeItem(storageKeyForProfileTaskDoneMsgShown);
    }
  }, [shouldShowProfileTaskDoneMsg, profileTasks]);

  React.useEffect(() => {
    if (shouldSetHasClickedMyinfoBanner(LocalStorageService)) {
      LocalStorageService.setItem('hasClickedMyinfoBanner', false);
    }
  });

  if (profileTasks.length === 0) {
    // user has completed all tasks
    return shouldShowProfileTaskDoneMsg ? <TaskDoneBanner /> : null;
  }

  return (
    userIsReady &&
    !isNewProfilePageEnabled && (
      <>
        <ProfileTasksWrapper>{renderTasks()}</ProfileTasksWrapper>
      </>
    )
  );
};

export default HocProfileManager(ProfileTasks);
