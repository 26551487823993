import React from 'react';
import ButtonText from '@ntuctech/devex-tangram/Button/ButtonText';
import SvgMenuHamb from '@ntuctech/devex-tangram/Icon/SvgMenuHamb';
import Link from '@ntuctech/devex-tangram/Link';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { isNudgeFlow } from '../../services/flow-from-url';
import { useUser } from '../../services/profile';
import MobileNavigation from '../MobileNavigation';

const PageHeaderSC = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  height: 4rem;
  background-color: #ffffff;
  border-bottom: 1px solid #eaeaea;
  z-index: 1;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  width: 100%;
  height: 100%;
  padding: 0 1rem;

  & a.accHeaderLogo:hover,
  & a.accHeaderLogo:focus,
  & a.accLogOutLink {
    box-shadow: none;
  }

  & a.accLogOutLink {
    font-weight: normal;
    color: #333333;
  }
`;

const LogoLinkpassSvg = styled.div`
  width: 7.3438rem;
  height: 1.875rem;
  background-image: url('https://media.nedigital.sg/linkpass/files/d7804ea8-2fb6-4cbe-bd6d-d628e2264fc0/Linkpass_logo.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
`;

const MenuButton = styled(ButtonText)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  justify-content: left;

  &:focus::before,
  &:active::before {
    box-shadow: none !important;
  }
`;

const PageHeader = () => {
  const router = useRouter();
  const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(false);

  const handleSignout = () => {
    router.push('/api/auth/logout');
  };

  const handleCloseMobileNav = () => {
    setIsMobileNavOpen(false);
  };

  const handleOpenMobileNav = () => {
    setIsMobileNavOpen(true);
  };

  const { loading } = useUser();
  const enableNudgeFlowUi = isNudgeFlow();

  return (
    <PageHeaderSC>
      <FlexContainer>
        <Link
          className="accHeaderLogo"
          href="/"
          title="Linkpass"
          aria-label="Linkpass"
          data-testid="app-logo"
          disabled={enableNudgeFlowUi}
        >
          <LogoLinkpassSvg data-testid="logo-linkpass-id" />
        </Link>

        {!enableNudgeFlowUi && (
          <MenuButton
            className="ndsHidden-m-up"
            color="grey"
            compact
            onClick={handleOpenMobileNav}
            data-testid="mobile-navigation-icon"
          >
            <SvgMenuHamb /> Menu
          </MenuButton>
        )}

        <MobileNavigation
          open={isMobileNavOpen}
          onClose={handleCloseMobileNav}
          onLogout={handleSignout}
        />

        {!enableNudgeFlowUi && !loading && (
          <Link
            data-testid="SignOutLink"
            className="ndsHidden-s-down accLogOutLink"
            onClick={handleSignout}
            title="Log out"
            aria-label="Log out"
          >
            Log out
          </Link>
        )}
      </FlexContainer>
    </PageHeaderSC>
  );
};

export default PageHeader;
