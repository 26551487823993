import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { datadogRum } from '@datadog/browser-rum';

export default function ErrorBoundary({ children, ...rest }) {
  return (
    <ReactErrorBoundary
      {...rest}
      onError={(error) => {
        // Using `.addError` so it can be processed by Error Tracking.
        // see: https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#collect-errors-manually
        datadogRum.addError(error);
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
}
