import React from 'react';
import ButtonText from '@ntuctech/devex-tangram/Button/ButtonText';
import ErrorBanner from '@ntuctech/devex-tangram/ErrorBanner';
import SvgAlert from '@ntuctech/devex-tangram/Icon/SvgAlert';
import SvgArrowDown from '@ntuctech/devex-tangram/Icon/SvgArrowDown';
import SvgArrowUp from '@ntuctech/devex-tangram/Icon/SvgArrowUp';
import SvgInfo from '@ntuctech/devex-tangram/Icon/SvgInfo';
import SvgTick from '@ntuctech/devex-tangram/Icon/SvgTick';
import SvgVerified from '@ntuctech/devex-tangram/Icon/SvgVerified';
import Typography from '@ntuctech/devex-tangram/Typography';
import format from 'date-fns/format';
import styled from 'styled-components';

import { FLAGS } from '../../services/feature-flag';
import {
  getVerificationState,
  myinfoIdentities,
  myInfokeys,
  newMyinfoPopupTitles,
} from '../../services/myinfo-verification';
import ContentBlock from '../ContentBlock';
import { useFeatures } from '../DynamicFeatureProvider';
import HocProfileManager from '../HocProfileManager';
import SimpleModal from '../SimpleModal';
import { CustomTooltip } from '../Theme';

const MyInfoMyInfoDetailsWrapper = styled.div`
  ol {
    margin: 0 auto;
  }

  ul li,
  ol li {
    margin-left: -1rem;
  }

  ul li::marker {
    color: #999999;
  }

  ol li::marker {
    font-family: 'Lato', sans-serif;
  }

  .updatedIdList {
    margin-left: 0.5rem;
    margin-top: 1rem;
  }

  .verfiedIdList {
    margin-left: 0.5rem;
    margin-top: 1rem;
    /* margin-bottom: 1rem; */
  }
`;

const UlSc = styled.ul`
  padding-top: 0;
  margin-top: 0;
`;

const HeaderSc = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 0.0625rem solid #eaeaea;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
`;

const HeaderLeftText = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeftTextTopRow = styled.div`
  display: flex;
  align-items: center;

  .verified-icon {
    margin-left: 0.625rem;
  }
`;

const TypographySubHeaderSc = styled(Typography)`
  color: #999999;
`;

const ButtonTextSc = styled(ButtonText)`
  padding: 0;
  margin: 0;
`;

const VerfiedInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ErrorBannerSc = styled(ErrorBanner)`
  margin-bottom: 1rem;
`;

const VerifiedDetailList = ({ idKeys, idList, verificationState, verifiedAttributes }) => {
  switch (verificationState) {
    case 'verified':
      return (
        <div className="verfiedIdList" data-testid="AllinfoVerified">
          {idKeys.map((key) => (
            <VerfiedInfoWrapper key={key}>
              <SvgTick color="#3FC078" size="s" />
              <Typography variant="body3">{idList[key]}</Typography>
            </VerfiedInfoWrapper>
          ))}
        </div>
      );
    case 'update':
      const isDiscountVerified =
        verifiedAttributes.merdekagen === true && verifiedAttributes.pioneergen === true;
      const verifiedList = idKeys.filter((key) => {
        if (key === 'discount' && isDiscountVerified) {
          return idList[key];
        }
        if (key !== 'discount' && verifiedAttributes[key]) {
          return idList[key];
        }
        return false;
      });
      const unverifiedList = idKeys.filter((key) => {
        if (key === 'discount' && !isDiscountVerified) {
          return idList[key];
        }
        if (key !== 'discount' && !verifiedAttributes[key]) {
          return idList[key];
        }
        return false;
      });
      return (
        <>
          <div className="updatedIdList" data-testid="PartiallyVerified">
            {verifiedList.map((identity) => (
              <VerfiedInfoWrapper key={identity}>
                <SvgTick color="#3FC078" size="s" />
                <Typography variant="body3">{idList[identity]}</Typography>
              </VerfiedInfoWrapper>
            ))}
          </div>
          <UlSc>
            {unverifiedList.map((identity) => (
              <li key={identity}>
                <Typography variant="body3">
                  <b>[New!]</b> {idList[identity]}
                </Typography>
              </li>
            ))}
          </UlSc>
        </>
      );
    default:
      return (
        <ul data-testid="NotVerified">
          {idKeys.map((key) => (
            <li key={key}>
              <Typography variant="body3">{idList[key]}</Typography>
            </li>
          ))}
        </ul>
      );
  }
};

const hideMyinfoUpdateButton = (myinfoVerificationState, showMyinfoUpdateFlag) => {
  return myinfoVerificationState === 'verified' && !showMyinfoUpdateFlag;
};

const ExistingAccountUi = ({ email }) => {
  return (
    <>
      <ErrorBannerSc
        label={
          <Typography variant="body3">
            Your Myinfo has already been verified under {<b>{email}</b>}
          </Typography>
        }
        icon={<SvgAlert />}
        attentionLevel="high"
      />
      <Typography variant="body2">To verify under another account:</Typography>
      <ol>
        <li>
          <Typography variant="body2">Log in with {<b>{email}</b>}</Typography>
        </li>
        <li>
          <Typography variant="body2">
            Go to Settings, and select &apos;Delete your Linkpass account&apos;
          </Typography>
        </li>
        <li>
          <Typography variant="body2">Your Myinfo will be verified in this account</Typography>
        </li>
      </ol>
    </>
  );
};

const contentSubheaderText = (isExistingAcc, isNewProfilePageEnabled) => {
  return isExistingAcc
    ? null
    : /* eslint-disable */
    isNewProfilePageEnabled
    ? 'Only the following data will be shared:'
    : 'Get faster and more convenient transactions when you verify your identity via Singpass';
};

const LeftTextTopIcon = ({ myinfoVerified, isExistingAcc, isNewProfilePageEnabled }) => {
  return myinfoVerified && !isExistingAcc ? (
    <SvgVerified size="s" color="#2070EF" className="verified-icon" />
  ) : (
    isNewProfilePageEnabled && (
      <CustomTooltip
        titleText={
          'Get verified with your MyInfo from Singpass, Singapore’s trusted digital identity.'
        }
      >
        <SvgInfo />
      </CustomTooltip>
    )
  );
};

const MyInfoDetails = (props) => {
  const { profile, handleOpenForm } = props;
  const { myinfoVerified, myinfoLastVerifiedDate, myinfoVerifiedAttributes, myinfoExistingData } =
    profile;
  const verificationState = getVerificationState(myinfoVerifiedAttributes);
  const [viewAll, setViewAll] = React.useState(false);
  const { isFlagEnabled } = useFeatures();
  const isNewProfilePageEnabled = isFlagEnabled(FLAGS.PROFILE_VERIFICATION_REVAMP);
  const isShowMyinfoUpdateButtonEnabled = isFlagEnabled(FLAGS.SHOW_MYINFO_UPDATE_BUTTON);
  const isMyinfoEnhancementEnabled = isFlagEnabled(FLAGS.MYINFO_ENHANCEMENT);
  const [openUpdate, setOpenUpdate] = React.useState(false);

  // myinfo conflict ui
  const isExistingAcc = myinfoExistingData?.uid;
  const existingEmail = myinfoExistingData?.email;

  return (
    isMyinfoEnhancementEnabled && (
      <ContentBlock>
        <SimpleModal
          open={openUpdate}
          title="New information added"
          body={
            <ul>
              {newMyinfoPopupTitles.map((identity) => (
                <li key={identity}>{identity}</li>
              ))}
            </ul>
          }
          ctaText="Update"
          ctaAction={handleOpenForm('myinfo')}
          secondaryText="Cancel"
          secondaryAction={() => setOpenUpdate(false)}
          testId="NewMyinfoPrompt"
        />
        <MyInfoMyInfoDetailsWrapper>
          <HeaderSc>
            <HeaderLeftText>
              <LeftTextTopRow>
                <Typography variant="h4" className="ndsHidden-xs-down">
                  Myinfo verification
                </Typography>
                <Typography variant="h5" className="ndsHidden-s-up">
                  Myinfo verification
                </Typography>
                <LeftTextTopIcon
                  myinfoVerified={myinfoVerified}
                  isExistingAcc={isExistingAcc}
                  isNewProfilePageEnabled={isNewProfilePageEnabled}
                />
              </LeftTextTopRow>
              <TypographySubHeaderSc variant="body4" data-testid="MyinfoVerification">
                {myinfoLastVerifiedDate && !isExistingAcc
                  ? `Last verified: ${format(new Date(myinfoLastVerifiedDate), 'MM/dd/yyyy')}`
                  : isNewProfilePageEnabled /* eslint-disable */
                  ? null
                  : 'Not verified'}
              </TypographySubHeaderSc>
            </HeaderLeftText>
            {!hideMyinfoUpdateButton(verificationState, isShowMyinfoUpdateButtonEnabled) && (
              <ButtonTextSc
                onClick={
                  verificationState === 'update'
                    ? () => setOpenUpdate(true)
                    : handleOpenForm('myinfo')
                }
                data-testid="VerifyMyinfo"
              >
                {myinfoVerified ? 'Update' : 'Verify'}
              </ButtonTextSc>
            )}
          </HeaderSc>
          <Typography variant="body3">
            {contentSubheaderText(isExistingAcc, isNewProfilePageEnabled)}
          </Typography>
          {isExistingAcc ? (
            <ExistingAccountUi email={existingEmail} />
          ) : viewAll ? (
            <VerifiedDetailList
              idKeys={myInfokeys}
              idList={myinfoIdentities}
              verificationState={verificationState}
              verifiedAttributes={myinfoVerifiedAttributes}
            />
          ) : (
            <VerifiedDetailList
              idKeys={myInfokeys.slice(0, 4)}
              idList={myinfoIdentities}
              verificationState={verificationState}
              verifiedAttributes={myinfoVerifiedAttributes}
            />
          )}
          {!isExistingAcc && (
            <ButtonTextSc onClick={() => setViewAll(!viewAll)}>
              {viewAll ? (
                <>
                  Hide <SvgArrowUp />
                </>
              ) : (
                <>
                  View All <SvgArrowDown />
                </>
              )}
            </ButtonTextSc>
          )}
        </MyInfoMyInfoDetailsWrapper>
      </ContentBlock>
    )
  );
};

export default HocProfileManager(MyInfoDetails);
