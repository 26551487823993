export const eventNames = {
  SAM_PROFILE_PAGE_VIEWED: 'sam profile page viewed',
  SAM_LINKPOINTS_PAGE_VIEWED: 'sam linkpoints page viewed',
  SAM_NOTIFICATIONS_PAGE_VIEWED: 'sam notifications page viewed',
  SAM_SETTINGS_PAGE_VIEWED: 'sam settings page viewed',
  ACC_REC_MERGE_ACC_VIEWED: 'merge account viewed',
  ACC_REC_MERGE_ACC_CLICKED: 'merge account confirmation clicked',
  ACC_REC_REVIEW_ACC_VIEWED: 'review account viewed',
  ACC_REC_REVIEW_ACC_CLICKED: 'review account clicked',
  ACC_REC_REVIEW_ACC_CLOSED: 'close button clicked',
  ACC_REC_REVIEW_ACC_CONFIRMATINO_CLICKED: 'review account confirmation clicked',
  ACC_RECC_MERGE_ACC_VER_CLICKED: 'merge account verification clicked',
  ACC_REC_MERGE_COMPLETE: 'merge account completed',
  ACC_REC_CLOSE_BUTTON: 'close button clicked',
  HPB_LOGIN_SUCCESS: 'hpb login success',
};

export const GTM_ENV_VAR = {
  AMPLITUDE_EVENT_NAME: 'amplitude_event_name',
  EVENT_ACTION: 'event_action',
};

export const ampVertical = {
  ACCOUNT: 'account',
  OMNI_VERTICAL: 'omni-vertical',
};

export const ctaLocations = {
  ACCOUNT: 'account/identity',
  ENTRANCE: 'entrance',
  MERGE_ACCOUNT: 'account | merge account',
  REVIEW_ACCOUNT: 'account | review account',
  NOT_MY_ACC_MENU: 'account | not my account menu',
  DO_NOT_MERGE_ACC_MENU: 'account | do not merge account menu',
  MERGE_ACCOUNT_DOB: 'account | merge account dob verification',
  MERGE_ACCOUNT_LINK_CARD: 'account | merge account link card verification',
  MERGE_ACCOUNT_SUCCESS: 'account | merge account success',
  MERGE_ACC_SUCCESS_CLOSE: 'account | merge account complete',
};

export const ctaSublocations = {
  BUTTON: 'button',
  EDIT_PROFILE: 'edit profile',
  ENTRANCE: 'entrance',
};

export const ampCategory = {
  ACCOUNT: 'account',
};
