import React from 'react';
import Box from '@ntuctech/devex-tangram/Box';
import Input from '@ntuctech/devex-tangram/Input';

import FormWrapper from '../FormWrapper';
import HocFormContent from '../HocFormContent';
import ProfileAction from '../ProfileAction';
import ProfileContent from '../ProfileContent';
import ProfileRow from '../ProfileRow';
import ProfileTitle from '../ProfileTitle';

const FormContent = (props) => {
  const {
    isSubmitButtonDisabled,
    values,
    errors,
    handleChange,
    handleBlur,
    submitForm,
    formActionFactory,
  } = props;

  const { name } = values;

  // need it to be boolean for error class to be shown
  const showNameError = Boolean(errors['name']);

  const handleKeyPress = React.useCallback(
    (event) => {
      if (event.key === 'Enter' && !isSubmitButtonDisabled) {
        event.target.blur();
        submitForm();
      }
    },
    [submitForm, isSubmitButtonDisabled],
  );

  return (
    <Box mt={3}>
      <FormWrapper hideInputLabel>
        <ProfileRow>
          <ProfileTitle>Preferred name</ProfileTitle>

          <ProfileContent>
            <Input
              id="EditNameInput"
              data-testid="EditNameInput"
              type="text"
              name="name"
              label="Name"
              helpText={showNameError ? errors.name : ''}
              error={showNameError}
              onChange={handleChange}
              onBlur={handleBlur}
              value={name}
              required
              fullWidth
              onKeyPress={handleKeyPress}
            />
            {formActionFactory({ name: 'Name', action: submitForm })}
          </ProfileContent>

          <ProfileAction className="ndsHidden-s-down">
            <></>
          </ProfileAction>
        </ProfileRow>
      </FormWrapper>
    </Box>
  );
};

export default HocFormContent(FormContent);
