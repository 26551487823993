import React from 'react';
import styled from 'styled-components';

const FormActionSC = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin: 0 0 0 0.5rem;
  }
`;

const FormAction = (props) => {
  const { children } = props;
  return <FormActionSC>{children}</FormActionSC>;
};

export default FormAction;
