import Switch from '@ntuctech/devex-tangram/Switch';
import styled from 'styled-components';

const FullWidthSwitch = styled(Switch)`
  display: block;
  > label {
    display: flex;
    margin: 0;
  }

  ._ndsSwitch__control {
    margin-left: auto;
  }
`;

export { FullWidthSwitch };
