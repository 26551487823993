import React from 'react';
import Checkbox from '@ntuctech/devex-tangram/Checkbox';
import Input from '@ntuctech/devex-tangram/Input';
import styled from 'styled-components';

const FullForm = styled.div`
  margin-bottom: 2.5rem;

  & .HasNoFloorUnitCheckbox {
    margin-top: 1rem;
  }
`;

const FloorAndUnit = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: -1rem;

  @media (max-width: 767px) {
    margin-bottom: -0.5rem;
  }

  & .FloorInput {
    margin-right: 0.25rem;
  }

  & .FloorInput,
  & .UnitInput {
    height: 4.5rem;
  }

  & ._ndsFormControlHelpText__message {
    position: absolute;
  }
`;

const FullAddressForm = (props) => {
  const {
    handleChange,
    handleBlur,
    blockNumber,
    showBlockNumberError,
    errors,
    roadName,
    showRoadNameError,
    buildingName,
    floorNumber,
    hasFloorUnitError,
    unitNumber,
    hasNoFloorUnit,
  } = props;
  return (
    <FullForm>
      <Input
        data-testid="BlockNumberInput"
        type="text"
        name="blockNumber"
        label="Block number"
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        value={blockNumber}
        required
        helpText={showBlockNumberError ? errors.blockNumber : ''}
        error={showBlockNumberError}
      />
      <Input
        data-testid="RoadNameInput"
        type="text"
        name="roadName"
        label="Road name"
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        value={roadName}
        required
        helpText={showRoadNameError ? errors.roadName : ''}
        error={showRoadNameError}
      />
      <Input
        data-testid="BuildingNameInput"
        type="text"
        name="buildingName"
        label="Building name"
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        value={buildingName}
      />
      <FloorAndUnit>
        <Input
          data-testid="FloorInput"
          className="FloorInput"
          type="text"
          name="floorNumber"
          label="Floor number"
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          value={floorNumber}
          required
          helpText={hasFloorUnitError ? errors.floorNumber || errors.unitNumber : ''}
          error={hasFloorUnitError}
          disabled={hasNoFloorUnit}
        />
        <Input
          data-testid="UnitInput"
          className="UnitInput"
          type="text"
          name="unitNumber"
          label="Unit number"
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          value={unitNumber}
          required
          error={hasFloorUnitError}
          disabled={hasNoFloorUnit}
        />
      </FloorAndUnit>
      <Checkbox
        data-testid="hasNoFloorUnitInput"
        name="hasNoFloorUnit"
        label="No floor and unit number"
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        checked={hasNoFloorUnit}
        className="HasNoFloorUnitCheckbox"
      />
    </FullForm>
  );
};

export default FullAddressForm;
