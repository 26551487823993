import React from 'react';
import Typography from '@ntuctech/devex-tangram/Typography';
import Head from 'next/head';
import styled from 'styled-components';

const StyledTypography = styled(Typography)`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;

  & > * {
    margin-right: 1rem;
  }
`;

export const StaticPageTitle = (props) => {
  const { children } = props;

  return (
    <>
      <Head>
        <title>{children} | Linkpass</title>
      </Head>

      <div className="ndsHidden-xs-down">
        <StyledTypography variant="h3" data-testid={`${children}_static_page_title`}>
          {children}
        </StyledTypography>
      </div>
      <div className="ndsHidden-s-up">
        <StyledTypography variant="h4" data-testid={`${children}_static_page_title`}>
          {children}
        </StyledTypography>
      </div>
    </>
  );
};
