import React from 'react';
import Typography from '@ntuctech/devex-tangram/Typography';
import styled from 'styled-components';

const ProfileTitleSC = styled.div`
  flex: 0 0 10rem;

  @media (max-width: 767px) {
    flex: 1 0 100%;

    & h6 {
      color: #666666;
    }
  }
`;

const ProfileTitle = (props) => {
  const { children } = props;
  return (
    <ProfileTitleSC className="accProfile-title">
      <div className="ndsHidden-xs-down">
        <Typography variant="body2">{children}</Typography>
      </div>
      <div className="ndsHidden-s-up">
        <Typography variant="subhead2">{children}</Typography>
      </div>
    </ProfileTitleSC>
  );
};

export default ProfileTitle;
