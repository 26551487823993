import encodeUri from '../encode-uri';

const redirectToMyinfoVerify = ({ uid, myinfoClientId }) => {
  const myinfoConfig = {
    authmode: 'SINGPASS',
    login_type: 'SINGPASS',
    attributes: 'name,sex,nationality,dob,merdekagen,pioneergen,residentialstatus,regadd',
    purpose: 'verifying your identity.',
    client_id: myinfoClientId,
    redirect_uri: process.env.MYINFO_REDIRECT_URI,
    state: window.btoa(`${uid}|${Date.now()}`),
  };

  const myinfoEndpoint = `${process.env.MYINFO_HOST}/com/v3/authorise?${encodeUri(myinfoConfig)}`;
  window.location.href = myinfoEndpoint;
};

const getVerificationState = (verifiedAttributes) => {
  if (
    Object.keys(verifiedAttributes).length === 0 ||
    Object.values(verifiedAttributes).every((value) => value === false)
  ) {
    return 'unverified';
  }
  if (Object.values(verifiedAttributes).every((value) => value === true)) {
    return 'verified';
  }
  return 'update';
};

const getDiscountType = ({ merdekagen, pioneergen }) => {
  switch (true) {
    case merdekagen:
      return 'Merdeka';
    case pioneergen:
      return 'Pioneer';
    default:
      return 'Not applicable';
  }
};

const myInfokeys = ['name', 'sex', 'dob', 'nationality', 'discount', 'residentialstatus', 'regadd'];

const myinfoIdentities = {
  name: 'Name',
  sex: 'Sex',
  dob: 'Date of birth',
  nationality: 'Nationality/Citizenship',
  discount: 'Discount Scheme Eligibility (Merdeka and Pioneer Generation)',
  residentialstatus: 'Residential Status',
  regadd: 'Registered Address',
};

const newMyinfoPopupTitles = [
  'Discount Scheme Eligibility (Merdeka and Pioneer Generation)',
  'Residential Status',
  'Registered Address',
];

const myinfoResidentStatus = {
  A: 'Others',
  C: 'Citizen',
  P: 'PR',
  U: 'Others',
  N: 'Others',
  F: 'Work Pass Holder',
};

export {
  redirectToMyinfoVerify,
  getVerificationState,
  getDiscountType,
  myInfokeys,
  myinfoIdentities,
  myinfoResidentStatus,
  newMyinfoPopupTitles,
};
