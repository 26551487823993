import React from 'react';
import Link from '@ntuctech/devex-tangram/Link';
import clsx from 'clsx';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import isNavActive from '../../services/navigation';

const NavLinkSC = styled(Link)`
  color: ${(props) => props.theme.ColorFontTypographyBase} !important;
  &:hover,
  &:active,
  &:focus,
  &.mobileNav__link--active {
    box-shadow: 0 0.0625rem ${(props) => props.theme.ColorFontTypographyBase};
  }
`;

function NavLink(props) {
  const router = useRouter();

  const { href, children } = props;

  const navActive = React.useMemo(() => isNavActive(router.pathname, href), [router, href]);

  const classNames = clsx({ 'mobileNav__link--active': navActive });

  return (
    <NextLink href={href} passHref legacyBehavior>
      <NavLinkSC href={href} className={classNames} size="l">
        {children}
      </NavLinkSC>
    </NextLink>
  );
}

export default NavLink;
