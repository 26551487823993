import React from 'react';
import dynamic from 'next/dynamic';

import { FLAG_ATTRIBUTES, FLAGS_VALUES } from '../../services/feature-flag';
import { useUser } from '../../services/profile';

import { FeatureContextProvider } from './FeatureContext';

// Dynamically import SplitFactory and SplitTreatments with SSR disabled
const SplitFactory = dynamic(
  () => import('@splitsoftware/splitio-react').then((mod) => mod.SplitFactory),
  { ssr: false },
);
const SplitTreatments = dynamic(
  () => import('@splitsoftware/splitio-react').then((mod) => mod.SplitTreatments),
  { ssr: false },
);

// userIsReady: user data is fetched
// isReady: Split is ready
// since there is no anonymous (guest) user for SAM, isReady also means user data is fetched
// there is no case where isReady is true but userIsReady is false
const DynamicFeatureProvider = ({ getConfig, children }) => {
  const { userIsReady } = useUser();

  if (!userIsReady) {
    return (
      <FeatureContextProvider isReady={false} features={[]}>
        {children}
      </FeatureContextProvider>
    );
  }

  return (
    <SplitFactory config={getConfig()}>
      <SplitTreatments names={FLAGS_VALUES} attributes={FLAG_ATTRIBUTES}>
        {(splitProps) => {
          return (
            <FeatureContextProvider
              isReady={splitProps.isReadyFromCache || splitProps.isReady}
              features={splitProps.treatments}
            >
              {children}
            </FeatureContextProvider>
          );
        }}
      </SplitTreatments>
    </SplitFactory>
  );
};

export default DynamicFeatureProvider;
