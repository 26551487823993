import React from 'react';
import SvgInfo from '@ntuctech/devex-tangram/Icon/SvgInfo';
import NotificationBanner from '@ntuctech/devex-tangram/NotificationBanner';
import styled from 'styled-components';

import { FLAGS } from '../../services/feature-flag';
import { useFeatures } from '../DynamicFeatureProvider';
import HocProfileManager from '../HocProfileManager';

const NotificationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;

  @media (max-width: 767px) {
    margin: 1rem 0;
  }
`;

const NotificationBannerSc = styled(NotificationBanner)`
  max-width: 45.75rem;
`;

const ProfileVerificationBanner = (props) => {
  const { profile } = props;
  const { phoneVerified } = profile;
  const { isFlagEnabled } = useFeatures();

  const isNewProfilePageEnabled = isFlagEnabled(FLAGS.PROFILE_VERIFICATION_REVAMP);

  return (
    !phoneVerified &&
    isNewProfilePageEnabled && (
      <NotificationWrapper>
        <NotificationBannerSc
          title="Protect your account from suspicious activity"
          text="We'll send a verification code to your verified methods below to confirm your identity."
          icon={<SvgInfo size="l" />}
          attentionLevel="high"
        />
      </NotificationWrapper>
    )
  );
};

export default HocProfileManager(ProfileVerificationBanner);
