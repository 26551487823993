import React from 'react';
import ButtonIcon from '@ntuctech/devex-tangram/Button/ButtonIcon';
import SvgArrowRight from '@ntuctech/devex-tangram/Icon/SvgArrowRight';
import Link from '@ntuctech/devex-tangram/Link';
import styled from 'styled-components';

const ProfileActionSC = styled.div`
  flex: 0 0 7rem;
  text-align: right;

  & a {
    font-weight: normal;
  }
  @media (max-width: 767px) {
    flex: 0 0 auto;
  }
`;

const ProfileAction = (props) => {
  const { children, onClick, type, className = '', ...rest } = props;

  if (type === 'icon') {
    return (
      <ButtonIcon onClick={onClick} {...rest} compact color="grey">
        <SvgArrowRight />
      </ButtonIcon>
    );
  }

  return (
    <ProfileActionSC className={`accProfile-action ${className}`}>
      <div className="ndsHidden-xs-down">
        <Link size="l" href="#" onClick={onClick} className={className} {...rest}>
          {children}
        </Link>
      </div>
      <div className="ndsHidden-s-up">
        <Link size="m" href="#" className={className} onClick={onClick}>
          {children}
        </Link>
      </div>
    </ProfileActionSC>
  );
};

export default ProfileAction;
