export const profileStatusChecklist = [
  {
    name: 'mobile_verification',
    isTaskComplete: (isUserReady, userData) =>
      isUserReady && Boolean(userData.phone_number_last_verified),
  },
  {
    name: 'myinfo_verification',
    isTaskComplete: (isUserReady, userData) =>
      isUserReady && Boolean(userData.myinfo_details?.myinfo_last_verified),
  },
  {
    name: 'email_verification',
    isTaskComplete: (isUserReady, userData) => isUserReady && Boolean(userData.email_last_verified),
  },
];

export const getProfileCompletionStatus = (isUserReady, userData) => {
  const profileCompletionStatus = {};

  for (const task of profileStatusChecklist) {
    const taskComplete = task.isTaskComplete(isUserReady, userData);
    profileCompletionStatus[task.name] = taskComplete;
  }

  return profileCompletionStatus;
};

export const getProfileTasks = (profileCompletionStatus) => {
  const profileTasks = [];

  for (const taskName in profileCompletionStatus) {
    if (profileCompletionStatus[taskName] !== true) {
      profileTasks.push(taskName);
    }
  }

  return profileTasks;
};
