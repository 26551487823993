import React from 'react';
import Box from '@ntuctech/devex-tangram/Box';
import TooltipDialog from '@ntuctech/devex-tangram/Tooltip/TooltipDialog';
import Typography from '@ntuctech/devex-tangram/Typography';
import styled from 'styled-components';

const TooltipDialogSc = styled(TooltipDialog)`
  svg {
    display: none;
  }
`;

const CustomTooltip = (props) => {
  return (
    <>
      <Box ml={1} />
      <TooltipDialogSc
        size="s"
        invertedBg
        title={
          <Typography variant="body3" mode="dark">
            {props.titleText}
          </Typography>
        }
        {...props}
      />
    </>
  );
};

export { CustomTooltip };
